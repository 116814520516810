import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";


// Fetch packages from the API
export const fetchPackages = createAsyncThunk(
  "packages/fetchPackages",
  async () => {
    const response = await axios.get(`${BASE_URL}/packages`);
    return response.data.data; // Adjust based on the API response structure
  }
);
export const fetchPackagesToAdmin = createAsyncThunk(
  "packages/fetchPackagesToAdmin",
  async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${BASE_URL}/packages/to-admin`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    console.log(response.data.data);
    
    return response.data.data; // Adjust based on the API response structure
  }
);

// Add package
export const addPackage = createAsyncThunk(
  "packages/addPackage",
  async (newPackage) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${BASE_URL}/packages`, newPackage, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  }
);

// Update package
export const updatePackage = createAsyncThunk(
  "packages/updatePackage",
  async ({ id, packageData }) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${BASE_URL}/packages/${id}`,
      packageData,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );

    console.log(response.data);

    return response.data;
  }
);

// Delete package
export const deletePackage = createAsyncThunk(
  "packages/deletePackage",
  async (id) => {
    const token = localStorage.getItem("token");
    await axios.delete(`${BASE_URL}/packages/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return id;
  }
);

const packagesSlice = createSlice({
  name: "packages",
  initialState: {
    items: [],
    packages: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // fetchPackagesToAdmin
      .addCase(fetchPackagesToAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPackagesToAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.packages = action.payload;
      })
      .addCase(fetchPackagesToAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addPackage.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(updatePackage.fulfilled, (state, action) => {
        state.status = "updateSucceeded";
        const index = state.items.findIndex(
          (pkg) => pkg.id === action.payload.id
        );
        if (index >= 0) state.items[index] = action.payload;
      })
      .addCase(deletePackage.fulfilled, (state, action) => {
        state.items = state.items.filter((pkg) => pkg.id !== action.payload);
      });
  },
});

export default packagesSlice.reducer;
