import React, { useEffect, useRef, useState } from "react";
import { TbWallet } from "react-icons/tb";
import { MdOutlineTranslate } from "react-icons/md";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { HiOutlineBell } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  changeToArabic,
  changeToEnglish,
} from "../redux/reducer/languageSlice";
import MobileMenu from "./MobileMenu";
import { MdMenu } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Notifications from "./Notifications";
import Avatar from "/public/Avatar.png";
import logo from "/public/Images/logo.png";
import { fetchCartItems } from "../redux/reducer/cartSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unReadCount, setUnReadCount] = useState(0); // State for unread notifications count
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.language.lang);
  const notificationsRef = useRef(null); // Create ref for notifications dropdown
  const cartItems = useSelector((state) => state.cart.items) || [];
  const { t, i18n } = useTranslation();

  const language = localStorage.getItem("language");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir =
      lng === "ar" ? dispatch(changeToArabic()) : dispatch(changeToEnglish());
    document.body.dir = lng === "ar" ? "rtl" : "ltr";
    localStorage.setItem("language", lng);
    console.log(lang);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      document.body.dir = savedLanguage === "ar" ? "rtl" : "ltr";
    }
  }, [i18n]);

  const handleClickOutside = (event) => {
    if (
      notificationsRef.current &&
      !notificationsRef.current.contains(event.target)
    ) {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    dispatch(fetchCartItems());
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const handleClick = (path) => {
    setOpen(false);
    setTimeout(() => {
      navigate(path);
    }, 0);
  };

  // Function to update unread notifications count
  const updateUnreadCount = (count) => {
    setUnReadCount(count);
  };

  return (
    <nav
      className={`${
        pathname === "/login" ? "hidden" : "flex"
      } bg-home px-4 py-5 flex-col lg:flex-row justify-between items-center content-center ${
        language === "en" ? "right-0" : "left-0"
      }`}
    >
      {/* Mobile Menu */}
      <div className="flex lg:hidden justify-between w-full items-center mb-4">
        <MdMenu
          className={`text-4xl cursor-pointer bg-menu-${user?.user?.user_type} text-white p-1 rounded-xl`}
          onClick={() => setOpen(!open)}
        />
        <MobileMenu open={open} handleClick={handleClick} />

        <Link to="/">
          <img src={logo} alt="" className="max-w-20 w-20 h-auto" />
        </Link>
      </div>

      <div className="flex flex-col lg:flex-row w-full justify-between lg:items-center">
        <form className="w-full lg:w-[50%] mb-2 lg:mb-0">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex items-center ps-5 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="#444050"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className={`border py-2 lg:py-3 ${
                language === "en" ? "pl-12" : "pr-12"
              } border-[#DADADA] rounded-full w-full`}
              placeholder={language === "en" ? "Search" : "بحث"}
              required
            />
          </div>
        </form>
        <ul
          className={`${
            language === "en" ? "lg:border-r" : "lg:border-l"
          } flex justify-between lg:justify-center items-center content-center lg:border-gray-300 mt-3 lg:mt-0`}
        >
          {user?.user.user_type != "super_admin" && (
            <Link
              to="/dashboard/wallet"
              className="border bg-white text-gray-400 rounded-full border-[#DADADA] relative pr-8 pl-3 py-2 lg:py-3 cursor-pointer min-w-32 mx-2 text-sm lg:text-md"
            >
              {language === "en" ? "$" : "ر.س"}{" "}
              {user?.user?.wallet?.balance || 0.0}{" "}
              <TbWallet className="absolute text-sm lg:text-xl text-gray-500 right-2 top-3" />
            </Link>
          )}

          <div className="flex">
            {/* <li className="text-sm lg:text-xl border bg-white text-gray-500 rounded-full border-[#DADADA] p-3 mx-3 cursor-pointer">
              <MdOutlineTranslate />
            </li> */}
            {user?.user.user_type != "super_admin" && (
              <li
                onClick={() => navigate("/dashboard/cart")}
                className="navbar-cart-icon relative text-sm lg:text-xl border bg-white text-gray-500 rounded-full cursor-pointer border-[#DADADA] p-3"
              >
                <AiOutlineShoppingCart />
                {cartItems.length > 0 && (
                  <span className="absolute top-0 left-0 text-center text-xs bg-red-500 text-white rounded-full w-4 h-4">
                    {cartItems.length}
                  </span>
                )}
              </li>
            )}

            <li
              ref={notificationsRef} // Attach ref to notification bell icon
              className="text-sm lg:text-xl border bg-white text-gray-500 rounded-full border-[#DADADA] p-3 mx-3 cursor-pointer relative"
              onClick={() => setShowNotifications(!showNotifications)}
            >
              <HiOutlineBell />
              {unReadCount > 0 && (
                <span className="absolute top-0 left-0 text-center text-xs bg-red-500 text-white rounded-full w-4 h-4">
                  {unReadCount}
                </span>
              )}
              <Notifications
                isOpen={showNotifications}
                updateUnreadCount={updateUnreadCount}
              />
            </li>
          </div>
        </ul>
        <Link
          to="/dashboard/profile"
          className={`hidden max-w-64 min-w-52 h-[3rem] rounded-full text-white bg-menu-${
            user?.user?.user_type
          } lg:flex relative justify-center items-center content-center flex-grow basis-0 ${
            language === "en" ? "pl-5 ml-3" : "pr-5 mr-3"
          }`}
        >
          <img
            className={`${
              language === "en" ? "left-0" : "right-0"
            } absolute h-[3rem]`}
            src={Avatar}
            alt="avatar"
          />
          <span className="w-full text-center">
            {user?.user?.user_name?.slice(0, 14)}
          </span>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
