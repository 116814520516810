import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  addPackage,
  fetchPackages,
  updatePackage,
} from "../../redux/reducer/packagesSlice";
import axios from "axios";
import edit from "/public/Icons/edit.svg";
import trash from "/public/Icons/trash.svg";
import { BASE_URL, BASE_URL_IMAGE } from "../../config";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FaRegFileImage } from "react-icons/fa";

const AddOrEditPackage = () => {
  const [existingPackage, setExistingPackage] = useState();
  const [imagePreview, setImagePreview] = useState();

  const dispatch = useDispatch();
  

  
  const navigate = useNavigate();
  const { id } = useParams();
  const [packageItems, setPackageItems] = useState([]);
  const [newItem, setNewItem] = useState({ en_title: "", ar_title: "" });
  const [editingItemId, setEditingItemId] = useState(null);


  const fetchPackageItems = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/package-item`, {
        headers: { Authorization: `Bearer ${JSON.parse(token)}` },
      });
      setPackageItems(response.data);
    } catch (error) {
      console.error("Error fetching package items:", error);
    }
  };

  
  useEffect(() => {
    window.scrollTo(0, 0);
    
    fetchPackageItems();
    if (id) {
      // Fetch existing package data for editing
      axios
        .get(`${BASE_URL}/packages/${id}`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        })
        .then((res) => {
          setExistingPackage(res.data.data);

          setImagePreview(res.data.image);
        })
        .catch(() => toast.error("Failed to fetch package details"));
    }
    
  }, [id]);

  useEffect(() => {
    if (existingPackage) {
      setImagePreview(existingPackage.logo ? `${BASE_URL_IMAGE}/${existingPackage.logo}` : "");
    }
  }, [existingPackage]);

  const handlePackageItemSelection = (itemId) => {
    setExistingPackage((prev) => {
      const isSelected = prev.package_items.some((pkg) => pkg.id === itemId);
      const updatedItems = isSelected
        ? prev.package_items.filter((pkg) => pkg.id !== itemId)
        : [
            ...prev.package_items,
            {
              ...packageItems.find((item) => item.id === itemId),
              pivot: {
                package_id: prev.id,
                package_item_id: itemId,
                value: "",
              },
            },
          ];
      return { ...prev, package_items: updatedItems };
    });
  };

  const handlePivotValueChange = (itemId, value) => {
    setExistingPackage((prev) => ({
      ...prev,
      package_items: prev.package_items.map((item) =>
        item.id === itemId ? { ...item, pivot: { ...item.pivot, value } } : item
      ),
    }));
  };

  const handleSaveItem = async () => {
    const token = localStorage.getItem("token");
    if (newItem.en_title && newItem.ar_title) {
      try {
        const updatedItem = { ...newItem };
        if (editingItemId) {
          await axios.put(
            `${BASE_URL}/package-item/${editingItemId}`,
            updatedItem,
            {
              headers: { Authorization: `Bearer ${JSON.parse(token)}` },
            }
          );
        } else {
          const response = await axios.post(
            `${BASE_URL}/package-item`,
            updatedItem,
            {
              headers: { Authorization: `Bearer ${JSON.parse(token)}` },
            }
          );
          setPackageItems([...packageItems, response.data]);
        }
        setNewItem({ en_title: "", ar_title: "" });
        setEditingItemId(null);
        dispatch(fetchPackages());
      } catch (error) {
        console.error("Error saving package item:", error);
      }
    }
  };

  const handleEditItem = (itemId) => {
    const itemToEdit = packageItems.find((item) => item.id === itemId);
    if (itemToEdit) {
      setNewItem({
        en_title: itemToEdit.en_title,
        ar_title: itemToEdit.ar_title,
      });
      setEditingItemId(itemId);
    }
  };
  const handleDeleteItem = async (itemId) => {
    const token = localStorage.getItem("token");
    try {
      await axios.delete(`${BASE_URL}/package-item/${itemId}`, {
        headers: { Authorization: `Bearer ${JSON.parse(token)}` },
      });
      setPackageItems(packageItems.filter((item) => item.id !== itemId));
      setExistingPackage((prev) => ({
        ...prev,
        package_item_ids: prev.package_item_ids.filter((id) => id !== itemId),
      }));
    } catch (error) {
      console.error("Error deleting package item:", error);
    }
  };

  const handleSavePackage = async () => {
    const selectedItems = existingPackage.package_items;
    const packageData = {
      en_title: existingPackage.en_title,
      ar_title: existingPackage.ar_title,
      en_description: existingPackage.en_description,
      ar_description: existingPackage.ar_description,
      price: existingPackage.price,
      package_type: existingPackage.package_type,
      has_store: existingPackage.has_store,

      package_items: selectedItems.map((item) => ({
        id: item.id,
        value: item.pivot.value,
      })),
    };

    try {
      if (existingPackage) {
        dispatch(updatePackage({ id: existingPackage.id, packageData }));
        toast.success("Package Updated Successfully");
      } else {
        dispatch(addPackage(packageData));
        toast.success("Package Added Successfully");
      }
      navigate("/admin-dashboard/packages");
    } catch (error) {
      toast.error("An error occurred while saving the package.");
    }
  };

  const handleFileChange = (e) => {
    const logo = e.target.files[0];
    if (logo) {
      setExistingPackage({ ...existingPackage, logo });
      setImagePreview(URL.createObjectURL(logo)); // Update preview with the selected image
    }
  };

  const handleUploadImage = async () => {
    if (!existingPackage.logo) {
      toast.error("من فضلك إختر صورة أولا");
      return;
    }

    const packageData = new FormData();
    packageData.append("logo", existingPackage.logo);

    dispatch(updatePackage({ id: existingPackage.id, packageData }));
  };

  if(!existingPackage){
    return <div className="flex items-center justify-center mt-16">
      <span className="loader mt-16"></span>
    </div>
  }

  return (
    <div className="flex flex-col justify-start p-6 bg-gray-50 min-h-screen bg-white">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">
        {existingPackage ? "تعديل باقة" : "إضافة باقة"}
      </h1>

      <div className="flex items-center gap-4 mb-6 pb-6 border-b">
        <div className="mx-3 flex flex-col items-center space-y-2">
          <img
            src={imagePreview || ""}
            alt="Package Preview"
            className="max-w-32 max-h-32 object-cover rounded-lg border p-1"
          />
          <label htmlFor="file" className="cursor-pointer mr-2 ">
            <FaRegFileImage className="text-2xl text-menu-super_admin inline" />
            <span className="m-1 text-blue-700 font-bold">إختر صورة جديدة</span>
          </label>
          <input
            className="hidden"
            type="file"
            name="file"
            id="file"
            onChange={handleFileChange}
          />
          <button
            onClick={handleUploadImage}
            className="w-32 me-2 bg-menu hover:bg-hover/85 text-white px-4 py-1 rounded-md"
          >
            {existingPackage?.logo ? "تحديث الصورة" : "رفع الصورة"}
          </button>
        </div>
      </div>

      {/* Package Basic Information */}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <input
          type="text"
          placeholder="عنوان الباقة باللغة الانجليزية (English)"
          value={existingPackage.en_title}
          onChange={(e) =>
            setExistingPackage({ ...existingPackage, en_title: e.target.value })
          }
          className="w-full px-4 py-2 mb-2 border rounded-lg"
        />
        <input
          type="text"
          placeholder="وصف الباقة باللغة الانجليزية(English)"
          value={existingPackage.en_description}
          onChange={(e) =>
            setExistingPackage({ ...existingPackage, en_description: e.target.value })
          }
          className="w-full px-4 py-2 mb-2 border rounded-lg"
        />
        <input
          type="text"
          placeholder="عنوان الباقة باللغة العربية"
          value={existingPackage.ar_title}
          onChange={(e) =>
            setExistingPackage({ ...existingPackage, ar_title: e.target.value })
          }
          className="w-full px-4 py-2 mb-2 border rounded-lg"
        />
        <input
          type="text"
          placeholder="وصف الباقة باللغة العربية"
          value={existingPackage.ar_description}
          onChange={(e) =>
            setExistingPackage({ ...existingPackage, ar_description: e.target.value })
          }
          className="w-full px-4 py-2 mb-2 border rounded-lg"
        />
        <input
          type="number"
          placeholder="السعر"
          value={existingPackage.price}
          onChange={(e) => setExistingPackage({ ...existingPackage, price: e.target.value })}
          className="w-full px-4 py-2 mb-2 border rounded-lg"
        />
        <input
          type="text"
          placeholder="نوع الباقة (monthly - yearly)"
          value={existingPackage.package_type}
          onChange={(e) =>
            setExistingPackage({ ...existingPackage, package_type: e.target.value })
          }
          className="w-full px-4 py-2 mb-2 border rounded-lg"
        />
      </div>

      {/* Add or Edit Package Item */}
      <h3 className="text-lg font-semibold mt-4">
        {editingItemId ? " تعديل ميزة" : "إضافة ميزة جديد"}
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
        <input
          type="text"
          placeholder="عنوان باللغة الانجليزية (English)"
          value={newItem.en_title}
          onChange={(e) => setNewItem({ ...newItem, en_title: e.target.value })}
          className="px-4 py-2 border rounded-lg"
        />
        <input
          type="text"
          placeholder="عنوان باللغة العربية"
          value={newItem.ar_title}
          onChange={(e) => setNewItem({ ...newItem, ar_title: e.target.value })}
          className="px-4 py-2 border rounded-lg"
        />
        <div className="flex item-center justify-center md:col-span-2">
          <button
            onClick={handleSaveItem}
            className="px-4 py-2 bg-menu-super_admin text-white rounded-lg hover:bg-menu-super_admin/80"
          >
            {editingItemId ? "تعديل" : "إضافة"}
          </button>
        </div>
      </div>

      {/* Package Item Selection */}
      <h3 className="text-lg font-semibold mt-4">الميزات المتاحة:</h3>
      <div className="overflow-x-auto">
        <div className="w-full min-w-[600px] ">
          {packageItems.map((item) => {
            const isSelected = existingPackage?.package_items?.some(
              (pkgItem) => pkgItem.id === item.id
            );
            const selectedItem = existingPackage.package_items.find(
              (pkg) => pkg.id === item.id
            );
            const pivotValue = selectedItem?.pivot?.value || "";

            return (
              <div
                key={item.id}
                className="flex items-center justify-between mb-2"
              >
                <div className=" flex items-center">
                  <input
                    type="checkbox"
                    checked={existingPackage.package_items.some(
                      (pkg) => pkg.id === item.id
                    )}
                    onChange={() => handlePackageItemSelection(item.id)}
                    className="me-2"
                  />
                  <span>{item.ar_title}</span>
                </div>

                <div className=" flex justify-end items-center">
                  {isSelected && (
                    <input
                      type="text"
                      placeholder="أدخل القيمة"
                      value={pivotValue}
                      onChange={(e) =>
                        handlePivotValueChange(item.id, e.target.value)
                      }
                      className="w-32 me-2 px-2 py-1 border rounded-lg"
                    />
                  )}
                  <button
                    onClick={() => handleEditItem(item.id)}
                    className="ml-2"
                  >
                    <img
                      src={edit}
                      alt="Edit"
                      className="xs:w-4 xs:h-4 md:min-w-5 md:min-h-5"
                    />
                  </button>
                  <button
                    onClick={() => handleDeleteItem(item.id)}
                    className="ml-2"
                  >
                    <img
                      src={trash}
                      alt="Delete"
                      className="xs:w-4 xs:h-4 md:min-w-5 md:min-h-5"
                    />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* File Upload */}
      <div className="mt-8">
        <input
          type="checkbox"
          className="me-2"
          checked={existingPackage.has_store}
          onChange={() =>
            setExistingPackage((prev) => ({ ...prev, has_store: !prev.has_store }))
          }
        />
        <label htmlFor="">اضافة متجر</label>
      </div>
      <div className="flex justify-end items-center mt-8">
        {/* Button to Save Package Data */}
        <button
          onClick={handleSavePackage}
          className="w-32 me-2 bg-menu-super_admin hover:bg-menu-super_admin/85 text-white px-4 py-2 rounded-md"
        >
          {existingPackage ? "تعديل البيانات" : "إضافة البيانات"}
        </button>
        <Link
          to="/admin-dashboard/packages"
          className="px-4 py-2 bg-menu-super_admin/20 hover:bg-menu-super_admin/30 rounded-md"
        >
          إلغاء
        </Link>
      </div>
    </div>
  );
};

export default AddOrEditPackage;
