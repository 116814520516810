import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import "./i18n";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-phone-number-input/style.css"; 
import "locomotive-scroll/dist/locomotive-scroll.css";
import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: "https://c4e3a5edb6d756a7237010ac345b1838@o4508461205815296.ingest.de.sentry.io/4508461207978064",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/sbyp-sa\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});




createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense
        fallback={
          <div className=" flex items-center justify-center h-screen ">
            <span className="loader">loading...</span>{" "}
          </div>
        }
      >
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={3}
          toastClassName={() =>
            `relative flex  justify-between bg-white text-menu-default max-w-xs md:max-w-sm lg:max-w-md h-16 p-4 pb-6  rounded-lg shadow-lg overflow-hidden`}
          bodyClassName={() => "flex items-center"}
          progressClassName="toast-progress"
        />
        <App />
      </Suspense>
    </BrowserRouter>
  </Provider>
);
