import { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";

const RolesPage = () => {
  const [roles, setRoles] = useState([]);
  const [permissionsByRole, setPermissionsByRole] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const [newRoleName, setNewRoleName] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(null);

  const toggleDropdown = (roleId) => {
    setDropdownOpen((prev) => (prev === roleId ? null : roleId));
  };

  const closeDropdown = () => setDropdownOpen(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".relative")) {
        closeDropdown();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const fetchRolesAndPermissions = async () => {
    try {
      const token = localStorage.getItem("token");

      // Fetch roles
      const rolesResponse = await axios.get(
        "https://backend-sbyp.sbyp-sa.com/api/roles/all",
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      setRoles(rolesResponse.data.data);

      // Fetch permissions
      const permissionsResponse = await axios.get(
        "https://backend-sbyp.sbyp-sa.com/api/permissions",
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      setPermissions(permissionsResponse.data.data);

      // Fetch permissions by role
      const permissionsPromises = rolesResponse.data.data.map(async (role) => {
        const response = await axios.get(
          `https://backend-sbyp.sbyp-sa.com/api/roles/permissions?role_name=${role.name}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        );
        return { roleName: role.name, permissions: response.data.data };
      });

      const permissionsData = await Promise.all(permissionsPromises);
      const permissionsMap = permissionsData.reduce(
        (acc, { roleName, permissions }) => {
          acc[roleName] = permissions;
          return acc;
        },
        {}
      );
      setPermissionsByRole(permissionsMap);

      setIsLoading(false);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchRolesAndPermissions();
  }, []);

  const saveRole = async () => {
    if (!newRoleName.trim()) {
      toast.error("اسم المسؤولية لا يمكن أن يكون فارغًا");
      return;
    }

    if (selectedPermissions.length === 0) {
      toast.error("يرجى اختيار صلاحية واحدة على الأقل");
      return;
    }

    try {
      const token = localStorage.getItem("token");

      const payload = {
        role_name: newRoleName,
        permissions: selectedPermissions,
      };

      // If in edit mode, include the role ID in the payload
      if (isEditMode && roleToEdit) {
        payload.id = roleToEdit.id;
      }

      await axios.post(
        "https://backend-sbyp.sbyp-sa.com/api/roles/create",
        payload,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );

      toast.success(
        roleToEdit ? "تم تعديل المسؤولية بنجاح" : "تم إنشاء المسؤولية بنجاح"
      );
      setIsModalOpen(false);
      setNewRoleName("");
      setSelectedPermissions([]);
      setRoleToEdit(null);
      fetchRolesAndPermissions();
    } catch (error) {
      console.error("خطأ في حفظ المسؤولية:", error);
      toast.error(
        roleToEdit ? "فشل في تعديل المسؤولية" : "فشل في إنشاء المسؤولية"
      );
    }
  };

  const deleteRole = async (roleId) => {
    const token = localStorage.getItem("token");

    try {
      const result = await Swal.fire({
        title: "هل أنت متأكد؟",
        text: "لن تتمكن من استعادة هذا الدور!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "نعم، احذفه!",
        cancelButtonText: "إلغاء",
      });

      if (result.isConfirmed) {
        await axios.delete(
          `https://backend-sbyp.sbyp-sa.com/api/roles/${roleId}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        );

        toast.success("تم حذف الدور بنجاح");
        fetchRolesAndPermissions();
      }
    } catch (error) {
      console.error("خطأ في حذف الدور:", error);
      toast.error("فشل في حذف الدور");
    }
  };

  const openModal = (role) => {
    if (role) {
      setIsEditMode(true);
      setRoleToEdit(role);
      setNewRoleName(role.name);
      setSelectedPermissions(
        permissionsByRole[role.name]?.map((perm) => perm.name) || []
      );
    } else {
      setIsEditMode(false);
      setNewRoleName("");
      setSelectedPermissions([]);
    }
    setIsModalOpen(true);
  };

  const cancelModal = () => {
    setIsModalOpen(false);
    setNewRoleName("");
    setSelectedPermissions([]);
    setRoleToEdit(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-bold">إدارة الصلاحيات</h1>
        <button
          onClick={() => openModal()}
          className="py-2 px-4 bg-menu-super_admin text-white rounded-xl hover:bg-menu-default"
        >
          إضافة مسؤولية جديدة <span className="text-xl">+</span>
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-4">
        {roles.map((role) => (
          <div
            key={role.id}
            className="p-4 bg-white rounded-xl shadow relative"
          >
            <h3 className="font-bold text-lg">{role.name}</h3>
            <button
              onClick={() => toggleDropdown(role.id)}
              className="absolute top-2 left-3 text-gray-500 hover:text-gray-700 text-xl"
            >
              ⋮
            </button>
            {dropdownOpen === role.id && (
              <AnimatePresence>
                <motion.div
                  key={`dropdown-${role.id}`}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="absolute top-8 left-2 bg-white shadow-lg rounded-lg p-2"
                >
                  <button
                    onClick={() => openModal(role)}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-gray-700"
                  >
                    تعديل
                  </button>
                  <button
                    onClick={() => deleteRole(role.id)}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100 text-red-500"
                  >
                    حذف
                  </button>
                </motion.div>
              </AnimatePresence>
            )}
            <ul className="mt-2 text-sm text-gray-600">
              {permissionsByRole[role.name]?.map((perm) => (
                <li key={perm.id}>- {perm.name}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <AnimatePresence>
          <motion.div
            key="modal -backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          >
            <motion.div
              key="modal-content"
              initial={{ opacity: 0, y: -200 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className="bg-white rounded-lg p-6 w-11/12 sm:w-96 relative"
            >
              <h2 className="text-lg font-semibold mb-4">
                {isEditMode ? "تعديل المسؤولية" : "إضافة مسؤولية جديدة"}
              </h2>
              <input
                type="text"
                value={newRoleName}
                onChange={(e) => setNewRoleName(e.target.value)}
                placeholder="اسم المسؤولية"
                className="p-2 border rounded-md w-full mb-4"
              />
              <div className="space-y-2">
                {permissions.map((permission) => (
                  <div key={permission.id} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`permission-${permission.id}`}
                      value={permission.name}
                      checked={selectedPermissions.includes(permission.name)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedPermissions((prev) => [
                            ...prev,
                            permission.name,
                          ]);
                        } else {
                          setSelectedPermissions((prev) =>
                            prev.filter((perm) => perm !== permission.name)
                          );
                        }
                      }}
                      className="appearance-none me-2 h-5 w-5 border-2 border-menu-default rounded-md checked:bg-menu-default checked:border-menu-default focus:outline-none focus:ring-0 focus:ring-menu-default"
                    />
                    <label
                      htmlFor={`permission-${permission.id}`}
                      className="text-sm"
                    >
                      {permission.name}
                    </label>
                  </div>
                ))}
              </div>
              <div className="flex justify-end mt-4">
                <button
                  onClick={saveRole}
                  className="px-4 py-2 bg-green-500 text-white rounded-md me-2"
                >
                  {isEditMode ? "تعديل" : "إضافة"}
                </button>
                <button
                  onClick={cancelModal}
                  className="px-4 py-2 bg-gray-400 text-white rounded-md"
                >
                  إلغاء
                </button>
              </div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default RolesPage;
