import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BiSolidShow } from "react-icons/bi";

const Complaints = () => {
  const [complaints, setComplaints] = useState([]);
  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch complaints
  useEffect(() => {
    axios
      .get("https://backend-sbyp.sbyp-sa.com/api/complaints", {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.valid) {
          setComplaints(response.data.complaints);
        } else {
          toast.error("Failed to fetch complaints!");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error fetching complaints.");
      });
  }, []);

  // Open modal to show details
  const openModal = (complaint) => {
    setSelectedComplaint(complaint);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setSelectedComplaint(null);
    setShowModal(false);
  };

  // Send notification to user
  const sendNotification = (email) => {
    if (!email) {
      toast.error("Email not found!");
      return;
    }

    axios
      .post("https://your-notification-api-endpoint.com/send", {
        email,
        subject: "Regarding Your Complaint",
        message: "We have reviewed your complaint. Thank you!",
      })
      .then(() => {
        toast.success("Notification sent successfully!");
      })
      .catch(() => {
        toast.error("Failed to send notification.");
      });
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">الشكاوى</h1>
      <div className="bg-white py-4 px-2 rounded-2xl">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[600px] mb-5 text-xs lg:text-sm overflow-hidden">
            <thead className="border-b text-start">
              <tr className="text-start">
                <th className=" px-4 py-2 text-right">ID</th>
                <th className=" px-4 py-2 text-right">الاسم</th>
                <th className=" px-4 py-2 text-right">الجوال</th>
                <th className=" px-4 py-2 text-right">الايميل</th>
                <th className=" px-4 py-2 text-right">العنوان</th>
                <th className=" px-4 py-2 text-right">الحالة</th>
                <th className=" px-4 py-2 text-right">الإجراءات</th>
              </tr>
            </thead>
            <tbody>
              {complaints.map((complaint) => (
                <tr key={complaint.id} className="border-b">
                  <td className=" px-4 py-2">{complaint.id}</td>
                  <td className=" px-4 py-2">{complaint.name}</td>
                  <td className=" px-4 py-2">{complaint.phone}</td>
                  <td className=" px-4 py-2">{complaint.email}</td>
                  <td className=" px-4 py-2">{complaint.title}</td>
                  <td className=" px-4 py-2">{complaint.status}</td>
                  <td className=" px-4 py-2">
                    <button
                      className=" text-white px-4 py-2 rounded"
                      onClick={() => openModal(complaint)}
                    >
                      <BiSolidShow className="text-menu-default text-2xl" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      {showModal && selectedComplaint && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-1/2">
            <h2 className="text-xl font-bold mb-4">تفاصيل الشكوى</h2>

            <p>
              <strong className="me-3">الاسم: </strong> {selectedComplaint.name}
            </p>
            <p>
              <strong className="me-3">الجوال:</strong>{" "}
              {selectedComplaint.phone}
            </p>
            <p>
              <strong className="me-3">الايميل:</strong>{" "}
              {selectedComplaint.email}
            </p>
            <p>
              <strong className="me-3">العنوان:</strong>{" "}
              {selectedComplaint.title}
            </p>
            <p>
              <strong className="me-3">التفاصيل:</strong>{" "}
              {selectedComplaint.description}
            </p>
            <p>
              <strong className="me-3">الحالة:</strong>{" "}
              {selectedComplaint.status}
            </p>
            <p>
              <strong className="me-3">تاريخ:</strong>{" "}
              {new Date(selectedComplaint.created_at).toLocaleString()}
            </p>

            <div className="mt-4 flex items-center justify-end">
              <button
                className="bg-green-500 hover:bg-green-500/90 text-white px-4 py-2 rounded me-2"
                onClick={() => sendNotification(selectedComplaint.email)}
              >
                إرسال بريد
              </button>
              <button
                className="bg-gray-500 hover:bg-gray-500/90 text-white px-4 py-2 rounded"
                onClick={closeModal}
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Complaints;
