import React, { useState } from 'react';
import axios from 'axios';

import { toast } from 'react-toastify';
import { BASE_URL } from '../../config';

const EditTicketModal = ({ ticket, onClose, onUpdate }) => {
    const [status, setStatus] = useState(ticket.status);

    const handleUpdateStatus = async () => {
        console.log(status);

        const data =
        {
            "lang": "ar",
            "status": status, // one of 'in_progress', 'resolved', 'closed'
            "resolved_at": "2024-12-11"
        }

        try {
            const token = localStorage.getItem('token');
            await axios.put(
                `${BASE_URL}/technical-support/${ticket.id}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(token)}`,
                    },
                }
            );
            toast.success('تم تحديث الحالة بنجاح');
            onUpdate(ticket.id, status);
            onClose();
        } catch (error) {
            console.error('Error updating status:', error);
            toast.error('حدث خطأ أثناء تحديث الحالة');
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
                <h2 className="text-lg font-bold mb-4">تعديل حالة التذكرة</h2>

                <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium">الحالة</label>
                    <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="w-full p-2 border rounded-lg"
                    >
                        <option value="resolved">محلولة</option>
                        <option value="in_progress">بانتظار الرد</option>
                        <option value="closed">مغلق</option>
                    </select>
                </div>

                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-gray-200 px-4 py-2 rounded-lg ms-2"
                    >
                        إلغاء
                    </button>
                    <button
                        onClick={handleUpdateStatus}
                        className="bg-menu-super_admin text-white px-4 py-2 rounded-lg"
                    >
                        تحديث
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditTicketModal;
