import { configureStore } from "@reduxjs/toolkit";
import  languageSlice  from "./reducer/languageSlice";
import authReducer from './reducer/authSlice';
import packagesSlice from './reducer/packagesSlice';
import productsSlice from './reducer/productsSlice';
import blogSlice from './reducer/blogSlice';
import cartSlice from './reducer/cartSlice';
import categoriesSlice from './reducer/categoriesSlice';
import myProductSlice from './reducer/myProductsSlice';
import paymentSlice from './reducer/paymentSlice';
import ticketSlice from './reducer/ticketSlice';


const store = configureStore({
    reducer:{
        language: languageSlice,
        auth:authReducer,
        packages:packagesSlice,
        products: productsSlice,
        blogs:blogSlice,
        cart : cartSlice,
        categories: categoriesSlice,
        myProducts: myProductSlice,
        payment:paymentSlice,
        tickets:ticketSlice
    }
})


export default store