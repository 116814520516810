import { IoIosArrowBack } from "react-icons/io";
import WelcomImg from "/public/Images/Frame-20.webp";
import WellcomeHand from "/public/Images/wellcome.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const WelcomeMsg = () => {
  const { user } = useSelector((state) => state.auth);
  console.log(user);
  return (
    <div className="relative xs:col-span-6 sm:col-span-3  lg:col-span-4  w-full m-auto  px-3">
      <div className="w-full m-auto relative">
        <img src={WelcomImg} alt="" className="min-h-[250px] max-h-[300px] " />
        <div className="absolute top-5 right-4 sm:top-6 sm:right-6 lg:top-10 lg:right-10 flex items-center">
          <p className="text-lg sm:text-2xl lg:text-4xl text-white">
            مرحبًا بعودتك , {user?.user.user_name}
          </p>
          <img src={WellcomeHand} className="w-[40px] lg:w-[60px]" />
        </div>
        <p className="absolute top-20 right-6 md:top-22 md:right-8 lg:top-24 lg:right-10 text-xs sm:text-sm md:text-md text-white font-light">
          إجمالي معدل التحويلات 0%
        </p>
        {(user?.user?.user_type === "supplier" ||
          user?.user?.user_type === "super_admin") && (
          <Link
            to={
              user?.user?.user_type === "super_admin"
                ? "/admin-dashboard/add-product"
                : "/dashboard/add-product"
            }
            className="flex justify-between items-center bg-black w-36 lg:w-48 py-2 lg:py-3 rounded-full px-3 absolute bottom-6 right-6 lg:bottom-12 lg:right-10 hover:bg-black/75"
          >
            <span className="mx-2 text-xs md:text-md lg:text-lg text-white">
              إضافة منتج جديد
            </span>
            <IoIosArrowBack className="w-6 h-6 lg:w-8 lg:h-8 bg-white rounded-full text-black " />
          </Link>
        )}
      </div>
    </div>
  );
};

export default WelcomeMsg;
