// src/features/blogs/blogSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";


// Thunks for async actions
export const fetchBlogs = createAsyncThunk(
  "blogs/fetchBlogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/blogs`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const addBlog = createAsyncThunk(
  "blogs/addBlog",
  async (formData, thunkAPI) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${BASE_URL}/blogs`, formData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });

      return response.data.blog;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateBlog = createAsyncThunk(
  "blogs/updateBlog",
  async ({ id, blogData }, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${BASE_URL}/blogs/${id}`, blogData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      return response.data; // Assuming it returns the updated blog
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteBlog = createAsyncThunk(`/blogs/deleteBlog`, async (id) => {
  const token = localStorage.getItem("token");
  await axios.delete(`${BASE_URL}/blogs/${id}`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(token)}`,
    },
  });
  return id; // Return the id of the deleted blog
});

// Blog slice
const blogSlice = createSlice({
  name: "blogs",
  initialState: {
    blogs: [],
    loading: false, // Initialize loading state
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogs.pending, (state) => {
        state.loading = true; // Set loading to true when fetching
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.loading = false; // Reset loading state
        state.blogs = action.payload; // Set blogs to the fetched data
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.loading = false; // Reset loading on error
        state.error = action.error.message; // Store error message
      })
      .addCase(addBlog.fulfilled, (state, action) => {
        state.blogs.push(action.payload); // Add the new blog
      })
      .addCase(updateBlog.fulfilled, (state, action) => {
        const index = state.blogs.findIndex(
          (blog) => blog.id === action.payload.id
        );
        if (index !== -1) {
          state.blogs[index] = action.payload; // Update the blog
        }
      })
      .addCase(deleteBlog.fulfilled, (state, action) => {
        state.blogs = state.blogs.filter((blog) => blog.id !== action.payload); // Remove the deleted blog
      });
  },
});

// Export the reducer as default
export default blogSlice.reducer;
