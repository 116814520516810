import React, { useEffect, useState } from 'react';
import searchIconButton from "/public/Icons/searchIconButton.png";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import edit from "/public/Icons/edit.svg";
import trash from "/public/Icons/trash.svg";
import { fetchTickets } from '../redux/reducer/ticketSlice';
import EditTicketModal from './components/EditTicketModal';

const AdminTickets = () => {
  const { user } = useSelector((state) => state.auth);
  const { tickets } = useSelector((state) => state.tickets);
  
  const [showModal, setShowModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of tickets per page
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTickets());
  }, [user]);

  // Open the modal and set the selected ticket
  const handleEditClick = (ticket) => {
    setSelectedTicket(ticket);
    setShowModal(true);
  };

  // Update ticket status locally after successful API update
  const handleUpdateStatus = () => {
    dispatch(fetchTickets());
  };

  // Pagination logic
  const totalPages = Math.ceil(tickets.length / itemsPerPage);
  const currentTickets = tickets.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle pagination controls
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  return (
    <div>
      <div className="flex flex-wrap justify-between align-center items-center my-3">
        <h3 className="text-2xl font-bold mb-2 lg:mb-0 mx-2">التذاكر</h3>
        <div className="flex justify-center">
          <div className="relative">
            <img
              src={searchIconButton}
              alt=""
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500"
            />
            <input
              type="search"
              id="default-search"
              className="ps-12 py-3 rounded-full w-[300px]"
              placeholder="بحث..."
              required
            />
          </div>
          {/* <Link
            to="/dashboard/add-ticket"
            className="bg-menu text-white text-3xl py-1 px-4 w-12 mx-2 rounded-full hover:bg-hover"
          >
            +
          </Link> */}
        </div>
      </div>

      <div className="bg-white py-4 px-2 rounded-2xl">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[600px] mb-5 text-xs lg:text-sm">
            <thead>
              <tr className="text-gray-500 text-sm">
                <th className="py-2"><input type="checkbox" /></th>
                <th className="py-2">رقم التذكرة</th>
                <th className="py-2">الاسم</th>
                <th className="py-2">اسم المتجر</th>
                <th className="py-2">تصنيف المشكلة</th>
                <th className="py-2">الحالة</th>
                <th className="py-2">الإجراءات</th>
              </tr>
            </thead>
            <tbody>
              {currentTickets.map((item) => (
                <tr key={item.id} className="border-t">
                  <td className="py-2"><input type="checkbox" /></td>
                  <td className="py-2">{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.store_name}</td>
                  <td>{item.problem_type}</td>
                  <td className="py-2">
                    <span
                      className={`${item?.status === "محلولة" || item?.status === "resolved"
                          ? "text-green-500 bg-green-100"
                          : item.status === "جديد"
                            ? "text-menu-super_admin bg-purple-100"
                            : item.status === "بانتظار الرد" || item.status === "pending" || item.status === "in_progress"
                              ? "text-cyan-500 bg-cyan-100"
                              : item.status === "مغلق" || item.status === "closed"
                                ? "text-red-500 bg-red-100"
                                : ""
                        } py-1 px-3 rounded-md`}
                    >
                      {item.status}
                    </span>
                  </td>
                  <td>
                    <div className="flex justify-around">
                      <button onClick={() => handleEditClick(item)}>
                        <img src={edit} alt="Edit" />
                      </button>
                      <button>
                        <img src={trash} alt="Delete" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <div className="flex justify-center mt-4">
            <button
              onClick={handlePrevPage}
              className="px-4 py-2 mx-2 bg-gray-200 rounded disabled:opacity-50"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="px-4 py-2">{`Page ${currentPage} of ${totalPages}`}</span>
            <button
              onClick={handleNextPage}
              className="px-4 py-2 mx-2 bg-gray-200 rounded disabled:opacity-50"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>

      {/* Modal for editing ticket status */}
      {showModal && selectedTicket && (
        <EditTicketModal
          ticket={selectedTicket}
          onClose={() => setShowModal(false)}
          onUpdate={handleUpdateStatus}
        />
      )}
    </div>
  );
};

export default AdminTickets;
