// src/components/AdminProductPage.js
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProducts, deleteProduct } from "./../redux/reducer/productsSlice";
import edit from "/public/Icons/edit.svg";
import trash from "/public/Icons/trash.svg";
import { Link } from "react-router-dom";
import { BASE_URL_IMAGE } from "../config";
import { toast } from "react-toastify";

const AdminProductPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Access products, loading, error, currentPage, totalPages from the Redux store
  const { products, loading, error, totalPages, totalItems, deleteSuccess } =
    useSelector((state) => state.products);
  const [currentPage, setCurrentPage] = useState(1); // Track current page

  useEffect(() => {
    dispatch(fetchProducts({ page: currentPage })); // Fetch products when the page changes
  }, [dispatch, currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page); // Update current page
    }
  };
  // Handle changing pages
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteProduct(id));
    if (deleteSuccess) {
      toast.success("تم حذف العنصر بنجاح");
    }
  };

  const handleEdit = (product) => {
    navigate("/admin-dashboard/add-product", { state: { product } });
  };

  const handleAdd = () => {
    navigate("/admin-dashboard/add-product");
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <span className="loader">Loading...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-600 text-center">Error: {error}</div>;
  }

  return (
    <div className="lg:container mx-auto p-4">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold mb-4">إدارة المنتجات</h1>
        <Link
          to="/admin-dashboard/add-product"
          className="text-3xl my-4 inline-flex justify-center items-center text-center bg-menu-super_admin text-white pb-1 w-10 h-10 rounded-full hover:bg-main"
          onClick={handleAdd}
        >
          +
        </Link>
      </div>

      <div className="bg-white py-4 px-2 rounded-2xl">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[600px] mb-5 text-xs lg:text-sm">
            <thead>
              <tr className="text-gray-500 text-sm">
                <th className="py-2 px-4 border-b">ID</th>
                <th className="py-2 px-4 border-b">المنتج</th>
                <th className="py-2 px-4 border-b">التصنيف</th>
                <th className="py-2 px-4 border-b">الوصف</th>
                <th className="py-2 px-4 border-b">السعر</th>
                <th className="py-2 px-4 border-b">المتجر</th>
                <th className="py-2 px-4 border-b">الكمية</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{product.id}</td>
                  <td className="py-2 px-4 border-b min-w-48">
                    <div className="flex  items-center space-x-2 space-x-reverse">
                      <img
                        src={`${BASE_URL_IMAGE}/${product.main_image}`}
                        alt=""
                        className=" w-10 h-10 rounded-full"
                      />
                      <span>
                        {product.ar_title
                          ? product.ar_title.slice(0, 20)
                          : "No Title"}
                      </span>
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b">
                    {product.category_ar_title || "No Category"}
                  </td>
                  <td className="py-2 px-4 border-b  min-w-48">
                    {" "}
                    {product.description
                      ? product.description.slice(0, 100)
                      : "No Description"}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {product.sale_price} ر.س
                  </td>
                  <td className="py-2 px-4 border-b">{product.store_name}</td>
                  <td className="py-2 px-4 border-b">{product.stock}</td>
                  <td className="py-2 px-4 border-b">
                    <div className="flex justify-around">
                      <button onClick={() => handleEdit(product)}>
                        <img src={edit} alt="" />
                      </button>
                      <button onClick={() => handleDelete(product.id)}>
                        <img src={trash} alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex items-center justify-between mt-8 w-full flex-wrap sm:flex-nowrap">
        <span className="text-sm text-gray-500 min-w-24 mb-4 sm:mb-0">
          عرض {products.length} من {totalItems} حسابات
        </span>

        <div className="flex items-center space-x-2 space-x-reverse flex-wrap sm:flex-nowrap">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="px-3 py-1 border border-gray-300 rounded-lg text-sm text-gray-500"
          >
            «
          </button>

          {/* Pagination Buttons for Mobile */}
          <div className="flex sm:hidden space-x-2 space-x-reverse">
            {[...Array(totalPages)]
              .slice(Math.max(currentPage - 2, 0), currentPage + 1)
              .map((_, i) => (
                <button
                  key={i}
                  className={`px-3 py-1 border border-gray-300 rounded-lg text-sm ${
                    currentPage === i + 1
                      ? "bg-menu-super_admin text-white"
                      : ""
                  }`}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
          </div>

          {/* Pagination Buttons for larger screens (full page range) */}
          <div className="hidden sm:flex space-x-2 space-x-reverse">
            {[...Array(totalPages)].map((_, i) => (
              <button
                key={i}
                className={`px-3 py-1 border border-gray-300 rounded-lg text-sm ${
                  currentPage === i + 1 ? "bg-menu-super_admin text-white" : ""
                }`}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-3 py-1 border border-gray-300 rounded-lg text-sm text-gray-500"
          >
            »
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminProductPage;
