import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addPackage,
  fetchPackages,
  deletePackage,
  fetchPackagesToAdmin,
} from "../redux/reducer/packagesSlice";
import { HiDotsVertical } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL_IMAGE } from "../config";
import trueIcon from "/public/Icons/true.png";
import { motion } from "framer-motion";

const PackagesAdmin = () => {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.packages.packages);
  const { lang } = useSelector((state) => state.language);
  const navigate = useNavigate();
  console.log(packages);

  const [newPackage, setNewPackage] = useState({
    lang,
    en_title: "",
    ar_title: "",
    en_description: "",
    ar_description: "",
    price: "",
    package_type: "",
    package_items: [{ en_title: "", ar_title: "" }],
    file: null, // Add file state
  });

  const [showArabicFields, setShowArabicFields] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editPackage, setEditPackage] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null); // state to manage dropdown visibility
  const closeDropdown = () => setDropdownOpen(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".relative")) {
        closeDropdown();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchPackagesToAdmin());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deletePackage(id));
    setDropdownOpen(null); // close the dropdown after delete
    dispatch(fetchPackagesToAdmin()); // Fetch packages after deletion
  };

  const handleEdit = (id) => {
    navigate(`/admin-dashboard/edit-package/${id}`);
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen ">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-3xl font-bold text-gray-800 ">إدارة الباقات</h1>
        <Link
          to="/admin-dashboard/packages/add-package"
          className="bg-menu-super_admin text-white text-4xl w-12 h-12 text-center   rounded-full hover:bg-hover "
        >
          +
        </Link>
      </div>

      {/* Package List */}
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {packages &&
          [...packages]
            .sort((a, b) => a.price - b.price)
            .map((pkg) => (
              <div
                key={pkg.id}
                className="p-6 bg-white  rounded-3xl relative text-center"
              >
                <div className="flex items-center justify-center my-6">
                  <img
                    src={`${BASE_URL_IMAGE}/${pkg.logo}`}
                    alt=""
                    className="w-36 h-36"
                  />
                </div>
                <h2 className="text-xl font-semibold text-gray-900 h-6">
                  {lang === "en" ? pkg.en_title : pkg.ar_title}
                </h2>
                <p className="text-gray-500 my-6 h-16">
                  {lang === "en" ? pkg.en_description : pkg.ar_description}
                </p>
                <p className="text--admin text-2xl mt-2 font-semibold mb-4">
                  ${pkg.price}
                </p>

                <ul className="list-disc  mt-2 text-gray-600 text-sm text-right space-y-3 ">
                  {pkg.package_items.map((item, index) => (
                    <li key={index}>
                      <div className="flex justify-between items-center ">
                        <span className="me-2 ">
                          {lang === "en" ? item.en_title : item.ar_title}
                        </span>
                        <span>
                          {item?.pivot.value ? (
                            item?.pivot.value
                          ) : (
                            <img
                              src={trueIcon}
                              className="min-w-4 min-h-4 w-4 h-4"
                            />
                          )}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>

                {/* Three Dots Menu */}
                <div className="absolute top-7 left-5">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdownOpen(dropdownOpen === pkg.id ? null : pkg.id);
                    }}
                    className="focus:outline-none"
                  >
                    <HiDotsVertical />
                  </button>
                  {dropdownOpen === pkg.id && ( // show dropdown if it matches the current package id
                    <motion.div
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.9 }}
                      transition={{ duration: 0.3 }}
                      className="absolute left-0 top-4 mt-2 w-32 bg-white shadow-lg rounded-lg border border-gray-200 z-10"
                    >
                      <button
                        onClick={() => handleEdit(pkg.id)}
                        className="block px-4 py-2 text-left text-gray-700 hover:bg-gray-100 w-full rounded-t-lg"
                      >
                        <Link to={`/admin-dashboard/edit-package/${pkg.id}`}>
                          تعديل
                        </Link>
                      </button>

                      <button
                        onClick={() => handleDelete(pkg.id)}
                        className="block px-4 py-2 text-left text-gray-700 hover:bg-gray-100 rounded-b-lg w-full"
                      >
                        حذف
                      </button>
                    </motion.div>
                  )}
                </div>
              </div>
            ))}
      </div>

      {/* Edit Modal */}
    </div>
  );
};

export default PackagesAdmin;
