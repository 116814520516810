import { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { BASE_URL } from "../config";
import axios from "axios";
import edit from "/public/Icons/edit.svg";
import trash from "/public/Icons/trash.svg";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
const Employees = () => {
  const [users, setUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [userTypeFilter, setUserTypeFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [totalPage, setTotalPage] = useState("");
  const packages = useSelector((state) => state.packages.packages);
  const [roles, setRoles] = useState([]); // State to store roles
  const [newUser, setNewUser] = useState({
    lang: "ar",
    full_name: "",
    user_name: "",
    user_type: "super_admin",
    position: "super_admin",
    phone: "",
    email: "",
    password: "",
    role_name: null, // Add role field
    package_name: null,
  });

  const perPage = 10;

  const token = localStorage.getItem("token");

  const filteredUsers = users.filter((user) => {
    const matchesSearch =
      user.user_name.toLowerCase().includes(search.toLowerCase().trim()) ||
      user.email.toLowerCase().includes(search.toLowerCase().trim());
    const matchesUserType = userTypeFilter
      ? user.user_type === userTypeFilter
      : true;
    return matchesSearch && matchesUserType;
  });
  const totalUsers = filteredUsers.length;
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * perPage,
    currentPage * perPage
  );

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(paginatedUsers.map((user) => user.id));
    }
    setSelectAll(!selectAll);
  };
  const handleSelectUser = (userId) => {
    setSelectedUsers((prevSelected) => {
      // Ensure prevSelected is an array before updating
      if (Array.isArray(prevSelected)) {
        return prevSelected.includes(userId)
          ? prevSelected.filter((id) => id !== userId)
          : [...prevSelected, userId];
      }
      return []; // Fallback to empty array if for some reason it's not an array
    });
  };
  const handleDeleteSelectedUsers = async () => {
    try {
      await Promise.all(
        selectedUsers.map((userId) =>
          axios.delete(`${BASE_URL}/users/${userId}`, {
            headers: {
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          })
        )
      );
      setUsers(users.filter((user) => !selectedUsers.includes(user.id)));
      setSelectedUsers([]);
      setSelectAll(false);
      fetchAllUsers();
      toast.success("تم الحذف بنجاح");
    } catch (error) {
      console.error("Failed to delete selected users:", error);
    }
  };
  const handleEdit = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  // Open delete modal and set selected user
  const handleDelete = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };
  const fetchAllUsers = async (
    url = `${BASE_URL}/users?user_type=super_admin&page=1`
  ) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      setTotalPage(response.data.data.last_page);
      setUsers((prevUsers) => [
        ...prevUsers,
        ...response.data.data.data.filter(
          (user) => !prevUsers.some((prevUser) => prevUser.id === user.id)
        ),
      ]);

      if (response.data.data.next_page_url) {
        fetchAllUsers(response.data.data.next_page_url);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };
  // Fetch roles from API
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://backend-sbyp.sbyp-sa.com/api/roles/all",
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        );
        console.log(response.data.data);

        setRoles(response.data.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
    fetchAllUsers();
  }, []);

  const handleAddUser = () => {
    setNewUser({
      lang: "ar",
      full_name: "",
      user_name: "",
      user_type: "super_admin",
      position: "super_admin",
      phone: "",
      email: "",
      password: "",
      role_name: null, // Reset role field
      package_name: null,
    });
    setShowAddModal(true);
  };

  const addNewUser = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/users`, newUser, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      setUsers([...users, response.data.user]);
      setShowAddModal(false);
      toast.success(response.data.message);
      setNewUser({
        full_name: "",
        user_name: "",
        user_type: "super_admin",
        position: "super_admin",
        phone: "",
        email: "",
        password: "",
        package_name: "",
        role_name: null,
      });
    } catch (error) {
      console.error("Error adding user:", error.response.data.errors);
      error.response.data.errors.map((error) => {
        toast.error(error);
      });
    }
  };
  // Save edited user data
  const saveEdit = async () => {
    const updatedUser = {
      lang: "ar",
      ...selectedUser,
    };
    await axios
      .post(`${BASE_URL}/users/${selectedUser.id}`, updatedUser, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      })
      .then((response) => {
        console.log(response.data.message);
        toast.success(response.data.message);
        setShowEditModal(false);
        setUsers(
          users.map((user) =>
            user.id === selectedUser.id ? response.data : user
          )
        );
      })
      .catch((err) => {
        toast.error(err.response.data.errors);
        err.response.data.errors.map((err) => toast.error(err));
      });

    setUsers(users.map((u) => (u.id === selectedUser.id ? updatedUser : u)));
    setShowEditModal(false);
  };
  const handleNextPage = () => {
    if (currentPage * perPage < totalUsers) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const handleClickSelectPage = (e) => {
    setCurrentPage(e);
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const confirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/users/${selectedUser.id}?lang=ar`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      console.log(response.data.message);

      setUsers(users.filter((u) => u.id !== selectedUser.id));
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(error.response.data.message);
    } finally {
      setShowDeleteModal(false);
    }
  };
  return (
    <div className="p-4 overflow-hidden mt-5">
      <div className="flex justify-between mb-4">
        <h1 className="font-bold text-2xl">المسؤولين</h1>
        <button
          onClick={handleAddUser}
          className="me-4 p-2 rounded-md bg-menu-super_admin text-white flex items-center"
        >
          <FaPlus className="me-1" /> إضافة حساب
        </button>
      </div>
      <div className="bg-white py-4 px-2 rounded-2xl">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[600px] mb-5 text-xs lg:text-sm overflow-hidden">
            <thead className="border-b text-start">
              <tr className="text-start">
                <th className="py-2">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  {/* إختيار الكل */}
                </th>
                <th className="p-2 text-right">id</th>
                <th className="p-2 text-right">الاسم</th>

                <th className="p-2 text-right">الايميل</th>
                <th className="p-2 text-right">الجوال</th>
                <th className="p-2 text-right">نوع الحساب</th>
                <th className="p-2 text-right">الصلاحية</th>

                <th className="p-2">إجراءات</th>
              </tr>
            </thead>
            <tbody>
              {paginatedUsers.map((user) => (
                <tr key={user.id} className="border-b">
                  <td className="py-2">
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleSelectUser(user.id)}
                    />
                  </td>
                  <td className="p-2">{user.id}</td>
                  <td className="p-2">{user.user_name}</td>
                  <td className="p-2">{user.email}</td>
                  <td className="p-2">{user.phone}</td>
                  <td className="p-2">{user.user_type}</td>
                  <td className="p-2">{user.role_name}</td>
                  <td className="p-2 flex justify-around">
                    <button onClick={() => handleEdit(user)}>
                      <img src={edit} alt="" />
                    </button>
                    <button onClick={() => handleDelete(user)}>
                      <img src={trash} alt="" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {selectedUsers.length > 0 && (
            <div className="flex justify-end">
              <button
                onClick={handleDeleteSelectedUsers}
                className="mt-4 p-2 bg-red-500 text-white rounded-md"
                disabled={selectedUsers.length === 0}
              >
                حذف الحسابات المحددة
              </button>
            </div>
          )}
          {/* Pagination Controls */}
          <div className="flex items-center justify-between mt-8 w-full">
            <span className="text-[9px] lg:text-sm text-gray-500 min-w-24">
              عرض {paginatedUsers.length} من {totalUsers} حسابات
            </span>
            <div className="flex items-center space-x-2 space-x-reverse">
              {/* Previous Page Button */}
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="px-3 py-1 border border-gray-300 rounded-lg text-[9px] lg:text-sm text-gray-500"
              >
                «
              </button>

              {/* Dynamic Pagination Logic */}
              {[...Array(Math.min(totalPage, 20))].map((_, i) => {
                const startPage = Math.max(1, currentPage - 10); // Start 10 pages before current
                const endPage = Math.min(totalPage, startPage + 19); // Limit to 20 pages
                const page = startPage + i;

                if (page > totalPage) return null; // Avoid rendering extra pages

                return (
                  <button
                    key={page}
                    className={`px-3 py-1 border border-gray-300 rounded-lg text-sm ${
                      currentPage === page
                        ? "bg-menu-super_admin text-white"
                        : ""
                    }`}
                    onClick={() => handleClickSelectPage(page)}
                  >
                    {page}
                  </button>
                );
              })}

              {/* Next Page Button */}
              <button
                onClick={handleNextPage}
                disabled={
                  currentPage * perPage >= totalUsers || totalUsers === 0
                }
                className="px-3 py-1 border border-gray-300 rounded-lg text-[9px] lg:text-sm text-gray-500"
              >
                »
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center w-screen h-screen">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3 }}
            className="bg-white p-4 rounded-lg w-4/5 lg:w-1/3"
          >
            <h3 className="text-lg font-bold mb-4">إضافة حساب جديد</h3>
            <input
              type="text"
              value={newUser.full_name}
              onChange={(e) =>
                setNewUser({ ...newUser, full_name: e.target.value })
              }
              placeholder="الأسم"
              className="p-2 border rounded-md w-full mb-4"
            />
            <input
              type="text"
              value={newUser.user_name}
              onChange={(e) =>
                setNewUser({ ...newUser, user_name: e.target.value })
              }
              placeholder="إسم المستخدم"
              className="p-2 border rounded-md w-full mb-4"
            />
            <input
              type="email"
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
              placeholder="الإيميل"
              className="p-2 border rounded-md w-full mb-4"
            />
            <input
              type="text"
              value={newUser.phone}
              onChange={(e) =>
                setNewUser({ ...newUser, phone: e.target.value })
              }
              placeholder="رقم الجوال"
              className="p-2 border rounded-md w-full mb-4"
            />
            <input
              type="password"
              value={newUser.password}
              onChange={(e) =>
                setNewUser({ ...newUser, password: e.target.value })
              }
              placeholder="كلمة المرور"
              className="p-2 border rounded-md w-full mb-4"
            />
            {/* <select
              value={newUser.user_type}
              onChange={(e) =>
                setNewUser({ ...newUser, user_type: e.target.value })
              }
              className="p-2 border rounded-md w-full mb-4"
            >
              
              <option value="super_admin">Super Admin</option>
            </select> */}
            {/* Role selector */}
            <select
              value={newUser.role_name} // Use role_name here
              onChange={
                (e) => setNewUser({ ...newUser, role_name: e.target.value }) // Update role_name instead of role
              }
              className="p-2 border rounded-md w-full mb-4"
            >
              <option value="">Select Role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.name}>
                  {role.name}
                </option>
              ))}
            </select>
            <div className="flex justify-end">
              <button
                onClick={() => setShowAddModal(false)}
                className="me-2 py-2 px-3 bg-gray-400 text-white rounded-md"
              >
                إلغاء
              </button>
              <button
                onClick={addNewUser}
                className="py-2 px-3 bg-blue-500 text-white rounded-md"
              >
                إضافة
              </button>
            </div>
          </motion.div>
        </div>
      )}

      {/* Edit Modal */}
      {showEditModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center w-screen h-screen ">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3 }}
            className="bg-white p-4 rounded-lg w-4/5 lg:w-1/3"
          >
            <h3 className="text-lg font-bold mb-4">تعديل بيانات حساب</h3>
            <input
              type="text"
              value={selectedUser.user_name}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, user_name: e.target.value })
              }
              className="p-2 border rounded-md w-full mb-4"
            />
            <input
              type="email"
              value={selectedUser.email}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, email: e.target.value })
              }
              className="p-2 border rounded-md w-full mb-4"
            />
            <input
              type="text"
              value={selectedUser.phone}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, phone: e.target.value })
              }
              className="p-2 border rounded-md w-full mb-4"
            />
            <select
              value={selectedUser.user_type}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, user_type: e.target.value })
              }
              className="p-2 border rounded-md w-full mb-4"
            >
              <option value="super_admin">Super Admin</option>
            </select>
            <select
              value={newUser.role_name} // Use role_name here
              onChange={
                (e) =>
                  setSelectedUser({
                    ...selectedUser,
                    role_name: e.target.value,
                  }) // Update role_name instead of role
              }
              className="p-2 border rounded-md w-full mb-4"
            >
              <option value="">Select Role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.name}>
                  {role.name}
                </option>
              ))}
            </select>
            <div className="flex justify-end">
              <button
                onClick={() => setShowEditModal(false)}
                className="me-2 py-2 px-4 bg-gray-400 text-white rounded-md"
              >
                إلغاء
              </button>
              <button
                onClick={saveEdit}
                className="p-2 py-2 px-4 bg-green-500 text-white rounded-md"
              >
                حفظ
              </button>
            </div>
          </motion.div>
        </div>
      )}

      {/* Delete Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center w-screen h-screen ">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3 }}
            className="bg-white p-4 rounded-lg w-4/5 lg:w-1/3"
          >
            <h3 className="text-lg font-bold mb-4">حذف حساب</h3>
            <p>هل أنت متأكد من أنك تريد حذف الحساب؟</p>
            <div className="flex justify-end">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="me-2 py-2 px-4 bg-gray-400 hover:bg-gray-300 text-white rounded-md"
              >
                إلغاء
              </button>
              <button
                onClick={confirmDelete}
                className="py-2 px-4 bg-red-500 hover:bg-red-800 text-white rounded-md"
              >
                حذف
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default Employees;
