import React from "react";
import Bars from "/public/Images/Bars.png";
import DotVetrical from "/public/Icons/dots-vertical.svg";
import BrandPaypal from "/public/Icons/brand-paypal.svg";
import Dollar from "/public/Icons/currency-dollar.svg";

const TotalEar = () => {
  return (
    <div className="w-full px-3 col-span-6 lg:col-span-2">
      <div className="bg-white rounded-xl p-6">
        <div className="flex justify-between items-center mb-4">
          <div className="text-gray-700 font-bold text-lg">اجمالي الارباح</div>
          <div className="text-gray-500">
            <img src={DotVetrical} alt="" />{" "}
          </div>
        </div>
        <div className="flex items-center mb-4">
          <span className="text-xl lg:text-4xl font-bold">0</span>
          <span className="text-green-500 text-sm mr-2">0</span>
        </div>
        <div className="mb-4">
          <img src={Bars} alt="Graph showing earnings" className="w-full" />
        </div>

        <div className="flex justify-between items-center mb-2">
          <span className="bg-purple-100 text-purple-500 p-2 rounded-md ">
            <img src={BrandPaypal} alt="" />
          </span>
          <div className="flex items-center w-[50%] mx-4">
            <div className="text-gray-700">
              <div>اجمالي الايرادات</div>
              <div className="text-sm text-gray-500">مدفوعات العملاء</div>
            </div>
          </div>
          <div className="text-green-500 font-bold"> 0 ر.س</div>
        </div>

        <div className="flex justify-between items-center mb-2">
          <span className="bg-purple-300 text-purple-500 p-2 rounded-md ">
            <img src={Dollar} alt="" />
          </span>
          <div className="flex items-center w-[50%] mx-4">
            <div className="text-gray-700">
              <div>اجمالي المبيعات</div>
              <div className="text-sm text-gray-500">استرداد</div>
            </div>
          </div>
          <div className=" flex-end text-green-500 font-bold">0 ر.س</div>
        </div>
      </div>
    </div>
  );
};

export default TotalEar;
