import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AddOrEditPackage from './AddOrEditPackage';

const EditPackageWrapper = ({ match }) => {
    const { id } = useParams();


    const packageData = useSelector((state) => state.packages.packages.find(pkg => pkg.id === Number(id)));


    return (
        <AddOrEditPackage existingPackage={packageData} />
    )
}

export default EditPackageWrapper