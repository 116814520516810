// src/components/AddProductPage.js
import  { useEffect } from "react";
import ProductForm from "./ProductForm";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addProduct, updateProduct } from "../../redux/reducer/productsSlice";
import { useLocation } from "react-router-dom";


const AddProductPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const product = location.state?.product || null; // Retrieve product from state if available



  useEffect(() =>{
    window.scrollTo(0, 0);
  },[])

  const handleSave = (newProduct) => {
    if (newProduct.id) {
      console.log(newProduct);
      
      // If there is an ID, update the existing product
      dispatch(updateProduct(newProduct));
    } else {
      // Otherwise, add a new product
      dispatch(addProduct(newProduct));
    }
    navigate("/admin-dashboard/products"); // Navigate back to the AdminProductPage
  };

  return (
    <div className="lg:container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">
      {product ? "تعديل منتج" : "أضافة منتج جديد"}
      </h1>
      <ProductForm product={product} onSave={handleSave} />
    </div>
  );
};

export default AddProductPage;

