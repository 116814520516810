import  { useEffect } from "react";
import WelcomeMsg from "../components/WelcomeMsg";
import TotalSalesMon from "../components/TotalSalesMon";
import TopSelling from "../components/TopSelling";
import SourceVisits from "../components/SourceVisits";
import SupportMonitor from "../components/SupportMonitor";
import TotalEar from "../components/TotalEar";
import MarketingCampaigns from "../components/MarketingCampaigns";

const AdminDashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-home row-span-2 grid grid-cols-6 gap-5 place-items-center lg:place-items-stretch mt-5 w-full overflow-hidden">
      <WelcomeMsg />
      <TotalSalesMon />
      <TopSelling />
      <SourceVisits />
      <SupportMonitor />
      <TotalEar />
      <MarketingCampaigns />
    </div>
  );
};

export default AdminDashboard;
