import React, { useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../config";
import axios from "axios";

const ComplaintForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDetails] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !phone || !description || !title || !email) {
      setMessage("كل المدخلات مطلوبه");
      return;
    }

    setLoading(true);
    const data = {
      lang: "ar",
      name,
      phone,
      email,
      title,
      description,
    };
    try {
      axios
        .post(`${BASE_URL}/complaints?lang=ar`, data)
        .then((res) => {
          console.log(res.data.message);

          toast.success("تم إرسال الشكوى بنجاح");
          setName("");
          setPhone("");
          setDetails("");
          setEmail("");
          setTitle("");
        })
        .catch((err) => {
          console.log(err);
          toast.error("حدث خطأ ما في إرسال الشكوى");
        });
    } catch (error) {
      setMessage("خطأ في إرسال الشكوى");
    }
    setLoading(false);
  };

  return (
    <div className="max-w-xl mx-auto p-6 border border-gray-300 rounded-lg my-16  w-[90%]">
      <h2 className="text-2xl mb-4">
        قم بكتابة بياناتك وإرسال الشكوى الخاصة بك
      </h2>
      {message && <p className="text-red-500 mb-2">{message}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block mb-1">
            الاسم
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-1">
            الايميل
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="phone" className="block mb-1">
            رقم الجوال
          </label>
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="title" className="block mb-1">
            عنوان الشكوى
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block mb-1">
            تفاصيل الشكوى
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDetails(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            rows="4"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full p-2 bg-menu-default text-white rounded"
          disabled={loading}
        >
          {loading ? "جاري الإرسال...." : "إرسال الشكوى"}
        </button>
      </form>
    </div>
  );
};

export default ComplaintForm;
