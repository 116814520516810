import { useEffect, useState } from "react";
import edit from "/public/Icons/edit.svg";
import trash from "/public/Icons/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import {
  fetchAllCategories,
  addCategory,
  updateCategory,
  deleteCategory,
} from "../redux/reducer/categoriesSlice";

const CategoryRow = ({ category, handleEdit, handleDelete }) => {
  return (
    <>
      <tr key={category.id} className="border-b">
        <td className="p-2">{category.id}</td>
        <td className="p-2">{category.ar_title}</td>
        <td className="p-2">{category.en_title}</td>
        <td className="p-2">{category.parent_id || "None"}</td>
        <td>
          <div className="flex justify-start space-x-2 space-x-reverse">
            <button onClick={() => handleEdit(category)}>
              <img src={edit} alt="Edit" />
            </button>
            <button onClick={() => handleDelete(category.id)}>
              <img src={trash} alt="Delete" />
            </button>
          </div>
        </td>
      </tr>
      {/* Render children recursively if they exist */}
      {category.children?.length > 0 &&
        category.children.map((child) => (
          <CategoryRow
            key={child.id}
            category={child}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ))}
    </>
  );
};

const AdminCategoriesPage = () => {
  const dispatch = useDispatch();
  const { parentCategories, loading, error, childrenCategories } = useSelector(
    (state) => state.categories
  );
  const [currentCategory, setCurrentCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lang, setLang] = useState("ar");
  const [ar_title, setArTitle] = useState("");
  const [en_title, setEnTitle] = useState("");
  const [parent_id, setParentId] = useState("");

  useEffect(() => {
    dispatch(fetchAllCategories());
  }, [dispatch]);

  const handleAddCategory = (e) => {
    e.preventDefault();
    const categoryData = {
      lang,
      ar_title,
      en_title,
      parent_id: parent_id || null,
    };
    if (currentCategory) {
      dispatch(updateCategory({ id: currentCategory.id, ...categoryData }));
      dispatch(fetchAllCategories());
    } else {
      dispatch(addCategory(categoryData));
      dispatch(fetchAllCategories());
    }
    resetForm();
  };

  const handleEdit = (category) => {
    setCurrentCategory(category);
    setLang(category.lang || "ar");
    setArTitle(category.ar_title);
    setEnTitle(category.en_title);
    setParentId(category.parent_id);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteCategory(id));
    dispatch(fetchAllCategories());
  };

  const resetForm = () => {
    setIsModalOpen(false);
    setCurrentCategory(null);
    setLang("ar");
    setArTitle("");
    setEnTitle("");
    setParentId("");
  };

  if (loading)
    return (
      <div className="flex items-center justify-center h-[400px]">
        <span className="loader">Loading...</span>
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="w-full px-2">
      <div className="flex items-center justify-between mb-4 mt-6">
        <h1 className="text-2xl font-bold mb-2">التصنيفات</h1>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-menu-super_admin hover:bg-menu-default text-2xl pb-1 text-white w-12 h-12 rounded-full flex items-center justify-center shadow-md"
        >
          +
        </button>
      </div>

      {/* Categories Table */}
      <div className="bg-white py-4 px-2 rounded-2xl mt-4">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[600px] mb-5 text-xs lg:text-sm overflow-hidden">
            <thead className="border-b text-start">
              <tr className="text-start">
                <th className="p-2 text-start">ID</th>
                <th className="p-2 text-start">العنوان باللغة العربية</th>
                <th className="p-2 text-start">العنوان باللغة الانجليزية</th>
                <th className="p-2 text-start">رقم تصنيف الاب</th>
                <th className="p-2 text-start">إجاراءات</th>
              </tr>
            </thead>
            <tbody>
              {childrenCategories.map((category) => (
                <CategoryRow
                  key={category.id}
                  category={category}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for Adding/Editing Category */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3 }}
            className="bg-white rounded-lg p-6"
          >
            <h2 className="text-lg font-bold my-4">
              {currentCategory ? "تعديل تصنيف" : "إضافة تصنيف"}
            </h2>
            <form onSubmit={handleAddCategory} className="space-y-4">
              <input
                type="text"
                value={ar_title}
                onChange={(e) => setArTitle(e.target.value)}
                placeholder="العنوان باللغة العربية"
                required
                className="border border-gray-200 p-2 w-full mb-2"
              />
              <input
                type="text"
                value={en_title}
                onChange={(e) => setEnTitle(e.target.value)}
                placeholder=" العنوان باللغة الانجليزية"
                required
                className="border border-gray-200 p-2 w-full mb-2"
              />
              <input
                type="text"
                value={parent_id}
                onChange={(e) => setParentId(e.target.value)}
                placeholder="رقم تصنيف الاب (إن وجد)"
                className="border border-gray-200 p-2 w-full mb-2"
              />
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-menu-super_admin hover:bg-menu-default text-white px-4 py-2 rounded"
                >
                  {currentCategory ? "تحديث التصنيف" : "إضافة تصنيف"}
                </button>
                <button
                  type="button"
                  onClick={resetForm}
                  className="mr-2 bg-gray-200 hover:bg-gray-400 px-4 py-2 rounded"
                >
                  إلغاء
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default AdminCategoriesPage;
