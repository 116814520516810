import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BASE_URL } from "../../config";
const token = localStorage.getItem("token");

// Fetch myProduct from the API
export const fetchMyProducts = createAsyncThunk(
  "myProducts/fetchMyProducts",
  async (page = 1, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/me-products?lang=ar&per_page=20&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },

        }
      );
      return response.data.data.data;
    } catch (error) {
      // Return a rejected value for consistent error handling
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Add myProduct
export const addMeProduct = createAsyncThunk(
  "products/addMeProduct",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/me-products`, formData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response?.data?.error || error.response?.data?.message);
      return rejectWithValue(
        error.response?.data?.error || error.response?.data?.message
      );
    }
  }
);

// Update product
export const updateProduct = createAsyncThunk(
  "products/updateProducts",
  async ({ productId, data }, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${BASE_URL}/me-products/${productId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const deleteMeProduct = createAsyncThunk(
  "products/deleteProduct",
  async (id, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${BASE_URL}/me-products/${id}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const myProductSlice = createSlice({
  name: "myProducts",
  initialState: {
    products: [],
    meProductLoading: false,
    meProductError: null,
    addSuccess: false,
    message: "",
  },
  reducers: {
    clearError: (state) => {
      state.error = null; // Add a reducer to clear errors if needed
    },
    clearAddSuccess: (state) => {
      state.addSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyProducts.pending, (state) => {
        state.meProductLoading = true;
        state.meProductError = null; // Clear any previous meProductErrors on a new request
      })
      .addCase(fetchMyProducts.fulfilled, (state, action) => {
        state.meProductLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchMyProducts.rejected, (state, action) => {
        state.meProductLoading = false;
        state.meProductError = action.payload; // Use the custom meProductError message from rejectWithValue
      })
      .addCase(addMeProduct.pending, (state) => {
        state.meProductLoading = true;
        state.meProductError = null;
      })
      .addCase(addMeProduct.fulfilled, (state, action) => {
        state.meProductLoading = false;
        state.products.push(action.payload);
        state.addSuccess = true;
      })
      .addCase(addMeProduct.rejected, (state, action) => {
        state.meProductLoading = false;
        state.meProductError = action.payload;
        state.addSuccess = false;
      })
      .addCase(updateProduct.pending, (state) => {
        state.meProductLoading = true;
        state.meProductError = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.meProductLoading = false;
        const index = state.products.findIndex(
          (product) => product.id === action.payload.id
        );
        if (index !== -1) {
          state.products[index] = action.payload;
        }
        state.message = action.payload.message;
        state.addSuccess = true;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.meProductLoading = false;
        state.meProductError = action.payload;
      })
       // Delete product
       .addCase(deleteMeProduct.fulfilled, (state, action) => {
        state.products = state.products.filter(
          (product) => product.id !== action.payload
        );
      })
      .addCase(deleteMeProduct.rejected, (state, action) => {
        state.meProductError = action.payload;
      });
  },
});

export const { clearError,clearAddSuccess } = myProductSlice.actions;
export default myProductSlice.reducer;
