import DashWhite from "/public/Icons/element-4.svg";
import DashDark from "/public/Icons/element-4-1.svg";
import ArchiveWhite from "/public/Icons/archive-book.svg";
import ArchiveDark from "/public/Icons/archive-book-1.svg";
import emptyWallet from "/public/Icons/empty-wallet.svg";
import emptyWalletDark from "/public/Icons/empty-wallet-1.svg";
import ShopWhite from "/public/Icons/shop-1.svg";
import ShopDark from "/public/Icons/shop-2.svg";
import BoxWhite from "/public/Icons/box.svg";
import BoxDark from "/public/Icons/box-1.svg";
import StarWhite from "/public/Icons/star.svg";
import StarDark from "/public/Icons/star-1.svg";
import ShapesWhite from "/public/Icons/shapes.svg";
import ShapesDark from "/public/Icons/shapes-1.svg";
import TicketWhite from "/public/Icons/ticket-2.svg";
import TicketDark from "/public/Icons/ticket-2-1.svg";
import Trolley from "/public/Icons/trolley-01.png";
import { FaUsersGear } from "react-icons/fa6";
import { TbPackages } from "react-icons/tb";
import { AiFillProduct } from "react-icons/ai";
import { LiaBlogSolid } from "react-icons/lia";
import { TbCategoryPlus } from "react-icons/tb";
import { IoNotificationsOutline } from "react-icons/io5";
import { BsPersonGear } from "react-icons/bs";
import { GiGearHammer } from "react-icons/gi";

export const getDashboardArrayFreePackage = (t) => [
  {
    text: t("Dashboards"),
    iconWhite: <img src={DashWhite} />,
    iconDark: <img src={DashDark} />,
    path: "/dashboard",
  },
  {
    text: t("ProductsCatalog"),
    iconWhite: <img src={ArchiveWhite} />,
    iconDark: <img src={ArchiveDark} />,
    path: "/dashboard/products",
  },
  {
    text: t("SubscriptionPlan"),
    iconWhite: <img src={StarWhite} />,
    iconDark: <img src={StarDark} />,
    path: "/dashboard/subscription-plan",
  },
];

export const getDashboardArraySeller = (t) => [
  {
    text: t("Dashboards"),
    iconWhite: <img src={DashWhite} />,
    iconDark: <img src={DashDark} />,
    path: "/dashboard",
  },
  {
    text: t("ProductsCatalog"),
    iconWhite: <img src={ArchiveWhite} />,
    iconDark: <img src={ArchiveDark} />,
    path: "/dashboard/products",
  },
  {
    text: t("Orders"),
    iconWhite: <img src={BoxWhite} />,
    iconDark: <img src={BoxDark} />,
    path: "/dashboard/orders",
  },
  {
    text: t("SubscriptionPlan"),
    iconWhite: <img src={StarWhite} />,
    iconDark: <img src={StarDark} />,
    path: "/dashboard/subscription-plan",
  },
  {
    text: t("Wallet"),
    iconWhite: <img src={emptyWallet} />,
    iconDark: <img src={emptyWalletDark} />,
    path: "/dashboard/wallet",
  },
  {
    text: t("Integrations"),
    iconWhite: <img src={ShapesWhite} />,
    iconDark: <img src={ShapesDark} />,
    path: "/dashboard/integrations",
  },
  {
    text: t("MyTickets"),
    iconWhite: <img src={TicketWhite} />,
    iconDark: <img src={TicketDark} />,
    path: "/dashboard/tickets",
  },
];

export const getDashboardArraySupplier = (t) => [
  {
    text: t("Dashboards"),
    iconWhite: <img src={DashWhite} />,
    iconDark: <img src={DashDark} />,
    path: "/dashboard",
  },
  {
    text: t("ProductsCatalog"),
    iconWhite: <img src={ArchiveWhite} />,
    iconDark: <img src={ArchiveDark} />,
    path: "/dashboard/products",
  },
  {
    text: t("MyProducts"),
    iconWhite: <img src={ShopWhite} />,
    iconDark: <img src={ShopDark} />,
    path: "/dashboard/my-products",
  },
  {
    text: t("Trolley"),
    iconWhite: <img src={ShopWhite} />,
    iconDark: <img src={Trolley} />,
    path: "/dashboard/added-products",
  },
  {
    text: t("Orders"),
    iconWhite: <img src={BoxWhite} />,
    iconDark: <img src={BoxDark} />,
    path: "/dashboard/orders",
  },
  {
    text: t("SubscriptionPlan"),
    iconWhite: <img src={StarWhite} />,
    iconDark: <img src={StarDark} />,
    path: "/dashboard/subscription-plan",
  },
  {
    text: t("Wallet"),
    iconWhite: <img src={emptyWallet} />,
    iconDark: <img src={emptyWalletDark} />,
    path: "/dashboard/wallet",
  },
  {
    text: t("Integrations"),
    iconWhite: <img src={ShapesWhite} />,
    iconDark: <img src={ShapesDark} />,
    path: "/dashboard/integrations",
  },
  {
    text: t("MyTickets"),
    iconWhite: <img src={TicketWhite} />,
    iconDark: <img src={TicketDark} />,
    path: "/dashboard/tickets",
  },
];

// export const getSuperAdminArray = (t) => [
//   {
//     text: t("Dashboards"),
//     iconWhite: <img src={DashWhite} />,
//     iconDark: <img src={DashDark} />,
//     path: "/admin-dashboard",
//   },
//   {
//     text: "المستخدمين",
//     iconWhite: <FaUsersGear className="" />,
//     iconDark: <FaUsersGear className="text-white" />,
//     path: "/admin-dashboard/users",
//   },
//   {
//     text: "المسؤولين",
//     iconWhite: <BsPersonGear className="" />,
//     iconDark: <BsPersonGear className="text-white" />,
//     path: "/admin-dashboard/employees",
//   },
//   {
//     text: "الصلاحيات",
//     iconWhite: <GiGearHammer className="" />,
//     iconDark: <GiGearHammer className="text-white" />,
//     path: "/admin-dashboard/roles",
//   },
//   {
//     text: "الباقات",
//     iconWhite: <TbPackages className="" />,
//     iconDark: <TbPackages className="text-white" />,
//     path: "/admin-dashboard/packages",
//   },

//   {
//     text: "المنتجات",
//     iconWhite: <AiFillProduct className="" />,
//     iconDark: <AiFillProduct className="text-white" />,
//     path: "/admin-dashboard/products",
//   },
//   {
//     text: "المقالات",
//     iconWhite: <LiaBlogSolid className="" />,
//     iconDark: <LiaBlogSolid className="text-white" />,
//     path: "/admin-dashboard/blogs",
//   },
//   {
//     text: "التصنيفات",
//     iconWhite: <TbCategoryPlus className="" />,
//     iconDark: <TbCategoryPlus className="text-white" />,
//     path: "/admin-dashboard/categories",
//   },
//   {
//     text: "الإشعارات",
//     iconWhite: <IoNotificationsOutline className="" />,
//     iconDark: <IoNotificationsOutline className="text-white" />,
//     path: "/admin-dashboard/notification",
//   },
//   {
//     text: "تذاكر الدعم",
//     iconWhite: <img src={TicketWhite} />,
//     iconDark: <img src={TicketDark} />,
//     path: "/admin-dashboard/tickets",
//   },
// ];
export const getSuperAdminArray = (t, permissions) => {
 
  // List of all menu items for super admin
  const menuItems = [
    {
      text: t("Dashboards"),
      iconWhite: <img src={DashWhite} />,
      iconDark: <img src={DashDark} />,
      path: "/admin-dashboard",
      requiredPermission: "Dashboard", // Example permission
    },
    {
      text: "المستخدمين",
      iconWhite: <FaUsersGear className="" />,
      iconDark: <FaUsersGear className="text-white" />,
      path: "/admin-dashboard/users",
      requiredPermission: "users",
    },
    {
      text: "المسؤولين",
      iconWhite: <BsPersonGear className="" />,
      iconDark: <BsPersonGear className="text-white" />,
      path: "/admin-dashboard/employees",
      requiredPermission: "employees",
    },
    {
      text: "الصلاحيات",
      iconWhite: <GiGearHammer className="" />,
      iconDark: <GiGearHammer className="text-white" />,
      path: "/admin-dashboard/roles",
      requiredPermission: "Roles",
    },
    {
      text: "الباقات",
      iconWhite: <TbPackages className="" />,
      iconDark: <TbPackages className="text-white" />,
      path: "/admin-dashboard/packages",
      requiredPermission: "packages",
    },
    {
      text: "المنتجات",
      iconWhite: <AiFillProduct className="" />,
      iconDark: <AiFillProduct className="text-white" />,
      path: "/admin-dashboard/products",
      requiredPermission: "products",
    },
    {
      text: "المقالات",
      iconWhite: <LiaBlogSolid className="" />,
      iconDark: <LiaBlogSolid className="text-white" />,
      path: "/admin-dashboard/blogs",
      requiredPermission: "blogs",
    },
    {
      text: "التصنيفات",
      iconWhite: <TbCategoryPlus className="" />,
      iconDark: <TbCategoryPlus className="text-white" />,
      path: "/admin-dashboard/categories",
      requiredPermission: "categories",
    },
    {
      text: "الإشعارات",
      iconWhite: <IoNotificationsOutline className="" />,
      iconDark: <IoNotificationsOutline className="text-white" />,
      path: "/admin-dashboard/notification",
      requiredPermission: "notification",
    },
    {
      text: "تذاكر الدعم",
      iconWhite: <img src={TicketWhite} />,
      iconDark: <img src={TicketDark} />,
      path: "/admin-dashboard/tickets",
      requiredPermission: "tickets",
    },
  ];
  if (Array.isArray(permissions)) {
    return menuItems.filter((item) =>
      permissions.includes(item.requiredPermission)
    );
  }
  // Filter menu items based on user's permissions
  return [];
};
