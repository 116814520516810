// src/pages/AdminBlogPage.js

import React, { useState, useEffect, useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchBlogs,
  addBlog,
  updateBlog,
  deleteBlog,
} from "../redux/reducer/blogSlice";
import { BASE_URL_IMAGE } from "../config";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

const AdminBlogs = () => {
  const dispatch = useDispatch();
  const { blogs } = useSelector((state) => state.blogs || []);
  const [blogData, setBlogData] = useState({
    title: "",
    description: "",
    images: [],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentBlogId, setCurrentBlogId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  const handleAddBlog = useCallback(() => {
    const formData = new FormData();
    formData.append("title", blogData.title);
    formData.append("description", blogData.description);

    // Append each image file from blogData.images
    blogData.images.forEach((image) => {
      formData.append("images", image);
    });

    if (isEditing) {
      dispatch(updateBlog({ id: currentBlogId, blogData: formData }))
        .unwrap()
        .then(() => resetForm())
        .catch((error) => toast.error("Failed to update blog: ", error));
    } else {
      dispatch(addBlog(formData))
        .unwrap()
        .then(() => resetForm())
        .catch((error) => toast.error(`Failed to add blog: ${error.message}`));
    }
  }, [dispatch, blogData, isEditing, currentBlogId]);

  const resetForm = () => {
    setIsEditing(false);
    setCurrentBlogId(null);
    setBlogData({ title: "", description: "", images: [] });
    setIsModalOpen(false);
  };

  const handleEdit = useCallback((blog) => {
    setIsEditing(true);
    setCurrentBlogId(blog.id);
    setBlogData({
      title: blog.title,
      description: blog.description,
      images: blog.images,
    });
    setIsModalOpen(true);
  }, []);

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteBlog(id))
        .unwrap()
        .catch((error) =>
          toast.error(`Failed to delete blog: ${error.message}`)
        );
    },
    [dispatch]
  );

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setBlogData({ ...blogData, images: files });
  };

  return (
    <div className="p-6  rounded-lg ">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-3xl font-bold ">إدارة المقالات</h1>

        {/* Add Blog Button */}
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-menu-super_admin text-white pb-1 rounded-full w-12 h-12 flex items-center justify-center text-3xl hover:bg-blue-600 transition duration-300"
        >
          +
        </button>
      </div>

      {/* Blog Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30 px-2">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3 }}
            className="bg-white p-6 rounded-lg shadow-md w-full max-w-md"
          >
            <h2 className="text-2xl font-semibold mb-4">
              {isEditing ? "Edit Blog" : "Add New Blog"}
            </h2>
            <div className="grid grid-cols-1 gap-4">
              <input
                type="text"
                placeholder="العنوان"
                value={blogData.title}
                onChange={(e) =>
                  setBlogData({ ...blogData, title: e.target.value })
                }
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-menu-super_admin"
              />
              <textarea
                placeholder="الوصف"
                value={blogData.description}
                onChange={(e) =>
                  setBlogData({ ...blogData, description: e.target.value })
                }
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-menu-super_admin h-24 resize-none"
              />
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-menu-super_admin"
              />
              <div className="flex items-center justify-end space-x-2 space-x-reverse">
                <button
                  onClick={handleAddBlog}
                  className="bg-menu-super_admin text-white rounded-md py-2 px-4 hover:bg-menu-super_admin/85 transition duration-300"
                >
                  {isEditing ? "تحديث" : "إضافة"} 
                </button>
                <button
                  onClick={resetForm}
                  className="bg-gray-400 text-white rounded-md py-2 px-4 hover:bg-gray-500 transition duration-300"
                >
                  إلغاء
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      )}

      {blogs.length === 0 ? (
        <div className="text-center text-gray-500 mt-8">
          No blogs available. Click the &quot; button to add a new blog.
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {blogs.map((blog) => (
            <BlogItem
              key={blog.id}
              blog={blog}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ))}
        </div>
      )}
    </div>
  );
};

// Memoized Blog Item Component
const BlogItem = memo(({ blog, onEdit, onDelete }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => setIsDropdownOpen(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".relative")) {
        closeDropdown();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <div className="p-6  bg-white rounded-2xl relative">
      {blog.images && blog.images.length > 0 && (
        <div className="flex flex-col items-center my-4">
          {blog.images.map((img, index) => (
            <img
              key={index}
              src={`${BASE_URL_IMAGE}/${img}`}
              alt={`Blog ${blog.title}`}
              className="w-64 h-60 object-content rounded-md"
            />
          ))}
        </div>
      )}
      <h3 className="text-lg font-semibold">{blog.title}</h3>
      <p className="text-gray-500">
        {(blog.description || "").slice(0, 100)}...
      </p>

      {/* Three-Dot Menu */}
      <div className="absolute top-4 left-4 ">
        <button
          onClick={toggleDropdown}
          className="text-gray-500 hover:text-gray-900 focus:outline-none"
        >
          &#x22EE;
        </button>
        {isDropdownOpen && (
          <motion.div  initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.3 }} className="absolute left-0 mt-2 w-28 bg-white border rounded-md shadow-xl z-30 ">
            <button
              onClick={() => {
                onEdit(blog);
                setIsDropdownOpen(false);
              }}
              className="block w-full px-4 py-2 text-center text-sm hover:bg-gray-100"
            >
              تعديل
            </button>
            <button
              onClick={() => {
                onDelete(blog.id);
                setIsDropdownOpen(false);
              }}
              className="block w-full px-4 py-2 text-center text-sm text-red-600 hover:bg-gray-100"
            >
              حذف
            </button>
          </motion.div>
        )}
      </div>
    </div>
  );
});

export default AdminBlogs;
