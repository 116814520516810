import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";

// Async Thunks for CRUD operations

// Fetch tickets
export const fetchTickets = createAsyncThunk(
  "tickets/fetchTickets",
  async () => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${BASE_URL}/technical-support`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data.data;
  }
);

// Create a new ticket
export const createTicket = createAsyncThunk(
  "tickets/createTicket",
  async ({ ticketData }) => {
    const token = localStorage.getItem("token")
    const response = await axios.post(
      `${BASE_URL}/technical-support`,
      ticketData,
      {
        headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );
    return response.data;
  }
);

// Edit a ticket
export const editTicket = createAsyncThunk(
  "tickets/editTicket",
  async ({ ticketId, ticketData }) => {
    const token = localStorage.getItem("token")
    const response = await axios.put(
      `${BASE_URL}/technical-support/${ticketId}`,
      ticketData,
      {
        headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );
    return response.data;
  }
);

// Delete a ticket
export const deleteTicket = createAsyncThunk(
  "tickets/deleteTicket",
  async ({ticketId }) => {
    const token = localStorage.getItem("token")
    await axios.delete(`${BASE_URL}/technical-support/${ticketId}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return ticketId; // Return ticket ID to remove it from the list
  }
);

// Create the slice
const ticketSlice = createSlice({
  name: "tickets",
  initialState: {
    tickets: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch tickets
      .addCase(fetchTickets.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTickets.fulfilled, (state, action) => {
        state.loading = false;
        state.tickets = action.payload;
      })
      .addCase(fetchTickets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Create a ticket
      .addCase(createTicket.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.tickets.push(action.payload);
      })
      .addCase(createTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Edit a ticket
      .addCase(editTicket.pending, (state) => {
        state.loading = true;
      })
      .addCase(editTicket.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.tickets.findIndex(
          (ticket) => ticket.id === action.payload.id
        );
        if (index !== -1) {
          state.tickets[index] = action.payload;
        }
      })
      .addCase(editTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Delete a ticket
      .addCase(deleteTicket.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.tickets = state.tickets.filter(
          (ticket) => ticket.id !== action.payload
        );
      })
      .addCase(deleteTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default ticketSlice.reducer;
