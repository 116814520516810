import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../config";
import { toast } from "react-toastify";

// Fetch Products from the API
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async ({ page }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/products?lang=ar&page=${page}&per_page=15`, // Added per_page=15
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );

      // Accessing the relevant data
      const { data, current_page, total, last_page } = response.data.data;

      return {
        data, // Products data array
        currentPage: current_page, // Current page
        totalItems: total, // Total number of items (products)
        totalPages: last_page, // Total number of pages
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Fetch filterProduct
export const filterProduct = createAsyncThunk(
  "products/filterProduct",
  async (filter, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/products?lang=ar&${new URLSearchParams(filter)}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      console.log(response.data.data.data);

      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Fetch User's Products
export const myProduct = createAsyncThunk(
  "products/myProduct",
  async (page, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/products/user?lang=ar&page=${page}&per_page=10`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      const { data, current_page, total, last_page } = response.data.data;

      return {
        data, // Products data array
        currentPage: current_page, // Current page
        totalItems: total, // Total number of items (products)
        totalPages: last_page, // Total number of pages
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Add Product
export const addProduct = createAsyncThunk(
  "products/add",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/products`, formData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Update Product
export const updateProduct = createAsyncThunk(
  "products/updateProducts",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/products/${id}`, data, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response?.data.errors);

      return rejectWithValue(
        error.response?.data?.errors || error.response?.data.message
      );
    }
  }
);

// Delete Product
export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${BASE_URL}/products/${id}`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      });
      toast.success('تم حذف المنتج بنجاح')
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    myProducts: [],
    filteredProducts: [],
    totalPages: 0, // Added totalPages to store pagination information
    totalItems: 0, // Added totalItems to store the total count of products
    loading: false,
    error: null,
    addSuccess: false,
    updateSuccess: false,
    deleteSuccess: false
  },
  reducers: {
    resetAddSuccess: (state) => {
      state.addSuccess = false; // Reset success status
    },
    clearError: (state) => {
      state.error = null; // Add a reducer to clear errors if needed
    },
    resetUpdateSuccess: (state) => {
      state.updateSuccess = false; // Reset success status
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch products
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.data; // Set the products data
        state.totalPages = action.payload.totalPages; // Set total pages
        state.totalItems = action.payload.totalItems; // Set total items
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    // fetch fillter products
    builder
      .addCase(filterProduct.fulfilled, (state, action) => {
        state.filteredProducts = action.payload; // Set filtered products in the state
        state.loading = false;
      })
      // Fetch user's products
      .addCase(myProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(myProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.myProducts = action.payload.data; // Set the products data
        state.totalPages = action.payload.totalPages; // Set total pages
        state.totalItems = action.payload.totalItems; // Set total items
      })
      .addCase(myProduct.rejected, (state, action) => {
        state.loading = false;
        state.meProductError = action.payload;
      })
      // Add product
      .addCase(addProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.products.push(action.payload);
        state.addSuccess = true;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.addSuccess = false;
      })
      // Update product

      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
        const index = state.products.findIndex(
          (product) => product.id === action.payload.id
        );
        if (index !== -1) {
          state.products[index] = action.payload;
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.updateSuccess = false;
      })
      // Delete product
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.products = state.products.filter(
          (product) => product.id !== action.payload
        );
        state.deleteSuccess = true;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.error = action.payload;
        state.updateSuccess = false;
      });
  },
});

export const { resetAddSuccess, resetUpdateSuccess, clearError } =
  productsSlice.actions;
export default productsSlice.reducer;
