// src/features/cart/cartSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../config";


// Thunks for async actions
export const fetchCartItems = createAsyncThunk(
  "cart/fetchCartItems",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token"); // Moved inside to get updated token
      const response = await axios.get(`${BASE_URL}/cart`, {
        headers: { Authorization: `Bearer ${JSON.parse(token)}` },
      });
      return response.data.cartItems;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const addItemToCart = createAsyncThunk(
  "cart/addItemToCart",
  async (addItem, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token"); // Always fetch the latest token
      const formattedItem = {
        lang: addItem.lang,
        product_id: String(addItem.product_id),
        quantity: String(addItem.quantity),
      };
      const response = await axios.post(`${BASE_URL}/cart/add-items`, formattedItem, {
        headers: { Authorization: `Bearer ${JSON.parse(token)}` },
      });
      toast.success(response.data.message);
      
      return response.data.cart;
    } catch (error) {
      toast.error(error.response?.data?.message);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchTotalPrice = createAsyncThunk(
  "cart/fetchTotalPrice",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token"); // Moved inside to get updated token
      const response = await axios.get(`${BASE_URL}/cart/total-price`, {
        headers: { Authorization: `Bearer ${JSON.parse(token)}` },
      });
      
      return response.data.total_price;
    } catch (error) {
      toast.error(error.response?.data?.message);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateItemQuantity = createAsyncThunk(
  "cart/updateItemQuantity",
  async ({ product_id, quantity, cart_item_id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${BASE_URL}/cart/edit-items/${cart_item_id}?lang=ar`,
        { product_id, quantity },
        {
          headers: { Authorization: `Bearer ${JSON.parse(token)}` },
        }
      );
      console.log(response.data.message);
      toast.success(response.data.message)
      return response.data.cart_item; // Returning the updated cart item
    } catch (error) {
      toast.error(error.response?.data?.message);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const deleteItemFromCart = createAsyncThunk(
  "cart/deleteItemFromCart",
  async (itemId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token"); // Moved inside to get updated token
      const response = await axios.delete(`${BASE_URL}/cart/delete/items/${itemId}?lang=ar`, {
        headers: { Authorization: `Bearer ${JSON.parse(token)}` },
      });
      console.log(response.data);
      
      toast.success(response.data.message)
      return response.data.cart_item;
    } catch (error) {
      toast.error(error.response?.data?.message);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Initial state
const initialState = {
  items: [],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  totalPrice: null,
  loadingItemId: null, // Track which item is currently being loaded
};

// Create cart slice
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch cart items
      .addCase(fetchCartItems.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Add item to cart
      .addCase(addItemToCart.pending, (state, action) => {
        state.loadingItemId = action.meta.arg.product_id; // Set loading state for the item being added
      })
      .addCase(addItemToCart.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = "succeeded";
        state.loadingItemId = null; // Reset loading state
      })
      .addCase(addItemToCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loadingItemId = null; // Reset loading state on failure
      })
      // Update item quantity
      .addCase(updateItemQuantity.pending, (state, action) => {
        state.loadingItemId = action.meta.arg.cart_item_id; // Set loading state for the item being updated
      })
      .addCase(updateItemQuantity.fulfilled, (state, action) => {
        const updatedItem = action.payload;
        state.items = state.items.map((item) =>
          item.cart_item_id === updatedItem.cart_item_id ? updatedItem : item
        );
        state.status = "succeeded";
        state.loadingItemId = null; // Reset loading state
      })
      .addCase(updateItemQuantity.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loadingItemId = null; // Reset loading state on failure
      })
      // Delete item from cart
      .addCase(deleteItemFromCart.pending, (state, action) => {
        state.loadingItemId = action.meta.arg; // Set loading state for the item being deleted
      })
      .addCase(deleteItemFromCart.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = "succeeded";
        state.loadingItemId = null; // Reset loading state
      })
      .addCase(deleteItemFromCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loadingItemId = null; // Reset loading state on failure
      })
      .addCase(fetchTotalPrice.fulfilled, (state, action) => {
        state.totalPrice = action.payload;
      })
      .addCase(fetchTotalPrice.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default cartSlice.reducer;
