import AlibabaLogo from "/public/Alibaba-Logo.png";

export const globalProduct = [
  {
    id: 1,
    main_image:
      "https://m.media-amazon.com/images/I/61SMFhy2suL._AC_SX679_.jpg",
    sale_price: "45.99",
    suggested_price: "50.99",
    description:
      "Men's Waterproof Sports Watch, Digital LED Display, Military Style",
    ar_title: "ساعة رياضية للرجال مقاومة للماء",
    category_ar_title: "ساعات",
    store_image: AlibabaLogo,
    stock: "9",
  },
  {
    id: 2,
    main_image:
      "https://m.media-amazon.com/images/I/61VMgvfzDkL._AC_SX679_.jpg",
    sale_price: "12.50",
    suggested_price: "15.99",
    description: "Wireless Bluetooth Earbuds with Noise Cancellation, Black",
    ar_title: "سماعات أذن لاسلكية بتقنية البلوتوث",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 3,
    main_image: "https://i.ebayimg.com/images/g/0JAAAOSwy65g-emT/s-l1600.webp",
    sale_price: "25.70",
    suggested_price: "29.99",
    description: "Smart Fitness Bracelet with Heart Rate Monitor, Black",
    ar_title: "سوار ذكي لمراقبة اللياقة البدنية",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 4,
    main_image:
      "https://m.media-amazon.com/images/I/61SG9bv5BgL._AC_SL1500_.jpg",
    sale_price: "8.99",
    suggested_price: "12.99",
    description: "Portable Mini USB Fan for Desk, Rechargeable",
    ar_title: "مروحة صغيرة محمولة تعمل عبر USB",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 5,
    main_image:
      "https://m.media-amazon.com/images/I/81i65E+wtgL._AC_SL1500_.jpg",
    sale_price: "39.99",
    suggested_price: "50.99",
    description: "Leather Wallet for Men with RFID Protection",
    ar_title: "محفظة جلدية للرجال بحماية RFID",
    category_ar_title: "إكسسوارات",
    store_image: AlibabaLogo,
  },
  {
    id: 6,
    main_image: "https://m.media-amazon.com/images/I/61elQ92q-JL._SL1500_.jpg",
    sale_price: "22.30",
    suggested_price: "29.99",
    description: "Wireless Charger Pad for iPhone and Samsung, Fast Charging",
    ar_title: "شاحن لاسلكي سريع للآيفون وسامسونج",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 7,
    main_image:
      "https://m.media-amazon.com/images/I/61T7Nxwx6GL._AC_SL1000_.jpg",
    sale_price: "18.99",
    suggested_price: "24.99",
    description: "Stainless Steel Insulated Travel Mug, 500ml",
    ar_title: "كوب سفر معزول من الفولاذ المقاوم للصدأ",
    category_ar_title: "أدوات منزلية",
    store_image: AlibabaLogo,
  },
  {
    id: 8,
    main_image:
      "https://m.media-amazon.com/images/I/61l6tbtcd6L._AC_SL1000_.jpg",
    sale_price: "59.99",
    suggested_price: "65.99",
    description: "Gaming Mouse with RGB Lights, Adjustable DPI",
    ar_title: "ماوس ألعاب بإضاءة RGB",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 9,
    main_image:
      "https://m.media-amazon.com/images/I/61vLYc0DQAL._AC_SL1500_.jpg",
    sale_price: "16.50",
    suggested_price: "20.99",
    description: "Wireless Bluetooth Headphones with Mic, Over-Ear",
    ar_title: "سماعات رأس لاسلكية مع ميكروفون",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 10,
    main_image:
      "https://lacasa-egy.s3.eu-central-1.amazonaws.com/public/products/14559/64P4iZ-blob",
    sale_price: "14.99",
    suggested_price: "26.99",
    description: "LED Desk Lamp with Adjustable Brightness",
    ar_title: "مصباح مكتب LED مع سطوع قابل للتعديل",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 11,
    main_image:
      "https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcS1Bv9bz4IziT5RxfHX8UbBiX6F8VA8YPHY1g4WGZ6W8tiNU-ZD1bERfkz8_t88JH0-pmyI1Y3jjFgbcg2cfjOsr2rXtt4_O8W1-UpwjvE7oRyBcOY9AP6Op9Z6Ub4r6JIQZD0R2vI&usqp=CAc",
    sale_price: "35.50",
    suggested_price: "40.99",
    description: "Foldable Laptop Stand, Adjustable Height",
    ar_title: "حامل كمبيوتر محمول قابل للطي",
    category_ar_title: "إكسسوارات الكمبيوتر",
    store_image: AlibabaLogo,
  },
  {
    id: 12,
    main_image:
      "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcT8iFYNVdcHOs8YpqjOpzEKIeyFSWpxgLwPUtsabntVbfyX_3zPLag8nekSk2utD2E_iMOCr2X7DlTDk62j_g5q1U9CjzopryG-aReZP5Q&usqp=CAc",
    sale_price: "19.99",
    suggested_price: "25.99",
    description: "Reusable Water Bottle, 1 Liter, BPA-Free",
    ar_title: "زجاجة ماء قابلة لإعادة الاستخدام",
    category_ar_title: "أدوات منزلية",
    store_image: AlibabaLogo,
  },
  {
    id: 13,
    main_image:
      "https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcQSf9EYH8LI-xyFxsQ_jkm_GAwelrPL0JRB1O7KCzHIVpUik2vsiot4xmfXSJs9sZvooDLY9XpbLK6rBqpyIjBqg182aDhk5ni8cjIIsXi84d0f2tthQn3BwzCs2XbgLfYYDIJTZwG4vQ&usqp=CAc",
    sale_price: "24.90",
    suggested_price: "30.99",
    description: "Stainless Steel Kitchen Knife Set, 5 Pieces",
    ar_title: "مجموعة سكاكين مطبخ من الفولاذ المقاوم للصدأ",
    category_ar_title: "أدوات مطبخ",
    store_image: AlibabaLogo,
  },
  {
    id: 14,
    main_image:
      "https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcQcrtIHBmS4w-tmBjzaoSeh_SN-99nkWH2IgRbHkjuXTwKjeXboafkvnKza0WVUgbZXavdgEAHgfuElo5CSDtIdIyYzmBvUUK9sOlkr-Er-yNqWcJS5C5C6My7urYvfU_WePcLqajVfy6A&usqp=CAc",
    sale_price: "27.99",
    suggested_price: "35.99",
    description: "Bluetooth Speaker with Deep Bass, Waterproof",
    ar_title: "مكبر صوت بلوتوث مع باس عميق",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 15,
    main_image:
      "https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcT0r6P4U8RYjaXM5uM8vZX2PA0iHjRWhHJ1eCVO74_wmO4BoxbfRyJfvXRRbt7A9h0Mbz6QahzItAtqPYJg1vNDvacDZU8J-WbWw47AiBZnipYfqRVE5gu7MSwFTy19WRBw_ATDmoI&usqp=CAc",
    sale_price: "75.00",
    suggested_price: "80.99",
    description: "Noise-Canceling Headphones, Over-Ear, Black",
    ar_title: "سماعات رأس عازلة للضوضاء",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 16,
    main_image:
      "https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcSO-nyIqx5i_ogVfj_hvhfAObCIo7HJr-qdJLnR4rF4qqq76dpl3d3w_TZGnsCpOmrXL-J09xoKONCueg3z7ujDOTDueXUHad9VcyzvuYidr5lQosbIpRgJwwR3ktMVyWIFLv292jHV&usqp=CAc",
    sale_price: "29.99",
    suggested_price: "35.99",
    description: "Wireless Keyboard and Mouse Combo, Black",
    ar_title: "مجموعة لوحة مفاتيح وفأرة لاسلكية",
    category_ar_title: "إكسسوارات الكمبيوتر",
    store_image: AlibabaLogo,
  },
  {
    id: 17,
    main_image:
      "https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcTyXxc_45KYHirD9FcgYD5qv6Lt9N0Ox57NY-IdBGfhRHstH0UDmw0kRbGG2jg07OcsP1ZOrpPvztDYMq5rXc4rdxF_w7AWjjWr04-CiHSFTiIFr8xv-bzGyhNBkKR--In3WWOVA3K6vA&usqp=CAc",
    sale_price: "49.99",
    suggested_price: "55.99",
    description: "4K HDMI Cable, High-Speed, 2 Meters",
    ar_title: "كابل HDMI بدقة 4K عالي السرعة",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 18,
    main_image:
      "https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcR0-fKEDdv8pGeoO9xdtH4NULjYdlPdpfI9H0NKpzHmh-ohGZrqALU17nDfuSBxsALTOU5MC3RAFlIqIu5WyYJ2taaV-YAVuHyR1cOBwv03spDIhMYG4kiJLWKV_fYaBnqFb-_WK1ZQ5c4&usqp=CAc",
    sale_price: "59.99",
    suggested_price: "69.99",
    description: "Smart Home Security Camera, 1080p",
    ar_title: "كاميرا أمان ذكية بدقة 1080p",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 19,
    main_image:
      "https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcSlKWGerQnPKp2FydctYcyi_2wMb_kCqjmOeHyCAp6AUAQgZ9OX4eYvSK1BFS8cRKxhSef0IsDqM2z6MmWmEGdlXhcWoG1nMeo4_fgm10iK2tmHw9miqmUzrltIHOWrhetRp8Q3yaAvQls&usqp=CAc",
    sale_price: "22.49",
    suggested_price: "27.99",
    description: "Silicone Cooking Utensils Set, 10 Pieces",
    ar_title: "مجموعة أدوات طهي من السيليكون",
    category_ar_title: "أدوات مطبخ",
    store_image: AlibabaLogo,
  },
  {
    id: 20,
    main_image:
      "https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcSugb29VJAsgmfQuAYeL8jNUF4qoyAKZ1oe-hqHVN4paFvqUFVIpHwb_OLrx0XVangR-R_IbDWaRXQA7KsNEU3TgFzGz55PKrxmdPR8XyGf8XfnWX8oWlNz8fX42lrDvQ&usqp=CAc",
    sale_price: "34.99",
    suggested_price: "39.99",
    description: "Adjustable Dumbbells, Pair, 5-25 lbs",
    ar_title: "دمبل قابلة للتعديل، زوج",
    category_ar_title: "معدات رياضية",
    store_image: AlibabaLogo,
  },
  {
    id: 21,
    main_image:
      "https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcQZuY35-tbiL1UkAyEh1oFOTb2nvA8dEE02wSyPjwdcHPr2X9dStWYY_1xKx32eEUgmbJiPPAGZgjUQLjaABmw8Miv-oKllh3avulTy0ZJxfLPnGSnbmJUrywASiqsAKQ&usqp=CAc",
    sale_price: "9.99",
    suggested_price: "14.99",
    description: "Portable Phone Stand, Foldable, Adjustable",
    ar_title: "حامل هاتف محمول قابل للطي",
    category_ar_title: "إكسسوارات الهاتف",
    store_image: AlibabaLogo,
  },
  {
    id: 22,
    main_image:
      "https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcTfARPQyGt_WttGMsJX1L_-_SAIvUMSkROqB729JfeCP6rzIyXlCUrax0ATiKjoPS9aSXqH-0oMAj3AsPvLXel4Iy586MuEHOpUTSEM-DNfDgPlbDIpU-DUSC3J5rIQFSh5xFqCDDktIQ&usqp=CAc",
    sale_price: "12.99",
    suggested_price: "16.99",
    description: "Stylus Pen for Touch Screens, Universal",
    ar_title: "قلم ستايلس للشاشات اللمسية",
    category_ar_title: "إكسسوارات الهاتف",
    store_image: AlibabaLogo,
  },
  {
    id: 23,
    main_image:
      "https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcQ4gz3YwABXTAMvB7Hqh3NjofiJqkCucSNwNKZzqOX6Jp8p6cAZaGNRDqyi44faPm9BzkipiIrGvUP9HAXWCHamvdU6Xj3oXXIkn0QQIb_pA6XPyHQmbnDiygDNjqo-CW2TlddCP130HQ&usqp=CAc",
    sale_price: "27.50",
    suggested_price: "32.99",
    description: "Electric Kettle, 1.7 Liter, Stainless Steel",
    ar_title: "غلاية كهربائية من الفولاذ المقاوم للصدأ",
    category_ar_title: "أدوات منزلية",
    store_image: AlibabaLogo,
  },
  {
    id: 24,
    main_image:
      "https://sifsof.com/wp-content/uploads/2017/10/SIFIT-7.91-mainpic.jpg",
    sale_price: "45.00",
    suggested_price: "55.00",
    description: "Bluetooth Fitness Tracker, Waterproof",
    ar_title: "متعقب لياقة بدنية بتقنية البلوتوث",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 25,
    main_image:
      "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcRjt61f3oxGXBnFqKWMJUzG7B3jK-RJLEbDPSdfXIFr_8x_u1-fExuSAerYBZ8VqjpvNoX1D7w498bI0mXPBsqxnLgTHXJ48lSA-dne21KKkiC9Ue6qsU7_LQ&usqp=CAc",
    sale_price: "19.90",
    suggested_price: "25.00",
    description: "Travel Pillow, Memory Foam, Ergonomic",
    ar_title: "وسادة سفر من الإسفنج الميموري",
    category_ar_title: "سفر",
    store_image: AlibabaLogo,
  },
  {
    id: 26,
    main_image:
      "https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcR0veTFMA_BsSDS1Mq2Ay2cNR26j0B32NA5uR3gX8PEDZTXzOnZv4PueWdG9_uGm0pfw6lZDo_v6FefmEUg8CuWalTN-tozWNw3x4Ow54cW&usqp=CAc",
    sale_price: "13.99",
    suggested_price: "18.99",
    description: "Glass Food Storage Containers, Set of 5",
    ar_title: "حاويات تخزين طعام زجاجية",
    category_ar_title: "أدوات منزلية",
    store_image: AlibabaLogo,
  },
  {
    id: 27,
    main_image:
      "https://m.media-amazon.com/images/I/81dBh9XuCBL._AC_SL1500_.jpg",
    sale_price: "89.99",
    suggested_price: "99.99",
    description: "Cordless Vacuum Cleaner, Lightweight",
    ar_title: "مكنسة كهربائية لاسلكية",
    category_ar_title: "أدوات منزلية",
    store_image: AlibabaLogo,
  },
  {
    id: 28,
    main_image:
      "https://m.media-amazon.com/images/I/61wpDRLJDML._AC_SL1322_.jpg",
    sale_price: "55.99",
    suggested_price: "65.99",
    description: "Smart Light Bulb, Color Changing, WiFi",
    ar_title: "لمبة ذكية متغيرة الألوان",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 29,
    main_image:
      "https://m.media-amazon.com/images/I/61ZJ6dvj05L._AC_SL1500_.jpg",
    sale_price: "42.75",
    suggested_price: "50.00",
    description: "Electric Hair Trimmer, Rechargeable",
    ar_title: "ماكينة حلاقة كهربائية قابلة لإعادة الشحن",
    category_ar_title: "إكسسوارات العناية الشخصية",
    store_image: AlibabaLogo,
  },
  {
    id: 30,
    main_image:
      "https://m.media-amazon.com/images/I/61ChfNU4+-L._AC_SL1500_.jpg",
    sale_price: "12.99",
    suggested_price: "17.99",
    description: "Wireless Mouse, Silent Click, USB",
    ar_title: "فأرة لاسلكية مع نقرة صامتة",
    category_ar_title: "إكسسوارات الكمبيوتر",
    store_image: AlibabaLogo,
  },
  {
    id: 31,
    main_image:
      "https://m.media-amazon.com/images/I/51GQm5gZv-L.__AC_SX300_SY300_QL70_ML2_.jpg",
    sale_price: "35.49",
    suggested_price: "40.99",
    description: "Yoga Mat, Non-Slip, 6mm Thickness",
    ar_title: "حصيرة يوجا غير قابلة للانزلاق",
    category_ar_title: "معدات رياضية",
    store_image: AlibabaLogo,
  },
  {
    id: 32,
    main_image:
      "https://m.media-amazon.com/images/I/71PArcLUNcL._AC_SL1500_.jpg",
    sale_price: "29.99",
    suggested_price: "34.99",
    description: "Portable Coffee Maker, Travel Size",
    ar_title: "صانعة قهوة محمولة بحجم السفر",
    category_ar_title: "أدوات منزلية",
    store_image: AlibabaLogo,
  },
  {
    id: 33,
    main_image:
      "https://m.media-amazon.com/images/I/71wHipJNjDL._AC_SL1500_.jpg",
    sale_price: "18.90",
    suggested_price: "22.90",
    description: "Electric Toothbrush, Rechargeable",
    ar_title: "فرشاة أسنان كهربائية قابلة لإعادة الشحن",
    category_ar_title: "العناية الشخصية",
    store_image: AlibabaLogo,
  },
  {
    id: 34,
    main_image: "https://m.media-amazon.com/images/I/411-ZiO-vNL._AC_.jpg",
    sale_price: "9.50",
    suggested_price: "12.99",
    description: "Reusable Shopping Bag, Foldable",
    ar_title: "حقيبة تسوق قابلة لإعادة الاستخدام",
    category_ar_title: "أدوات منزلية",
    store_image: AlibabaLogo,
  },
  {
    id: 35,
    main_image:
      "https://m.media-amazon.com/images/I/81Y692Q1RfL._AC_SL1500_.jpg",
    sale_price: "28.00",
    suggested_price: "33.99",
    description: "Insulated Lunch Bag, Leakproof",
    ar_title: "حقيبة غداء معزولة مانعة للتسرب",
    category_ar_title: "أدوات منزلية",
    store_image: AlibabaLogo,
  },
  {
    id: 36,
    main_image:
      "https://m.media-amazon.com/images/I/71GfLIrtFUL._AC_SL1500_.jpg",
    sale_price: "15.99",
    suggested_price: "22.99",
    description: "Portable Power Bank, 10000mAh",
    ar_title: "باور بانك محمول، 10000 مللي أمبير",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 37,
    main_image:
      "https://m.media-amazon.com/images/I/613UKVXnQ3L._AC_SL1156_.jpg",
    sale_price: "35.00",
    suggested_price: "45.99",
    description: "Noise Cancelling Bluetooth Headphones",
    ar_title: "سماعات بلوتوث مع إلغاء الضوضاء",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 38,
    main_image: "https://m.media-amazon.com/images/I/41An5IrZWVL._AC_.jpg",
    sale_price: "19.99",
    suggested_price: "29.99",
    description: "Ergonomic Office Chair",
    ar_title: "كرسي مكتب مريح",
    category_ar_title: "أثاث",
    store_image: AlibabaLogo,
  },
  {
    id: 39,
    main_image:
      "https://m.media-amazon.com/images/I/41QWrxOUpqL._AC_SL1206_.jpg",
    sale_price: "9.99",
    suggested_price: "14.99",
    description: "Stainless Steel Water Bottle",
    ar_title: "زجاجة ماء من الفولاذ المقاوم للصدأ",
    category_ar_title: "أدوات منزلية",
    store_image: AlibabaLogo,
  },
  {
    id: 40,
    main_image:
      "https://m.media-amazon.com/images/I/71iBrsQzbqL._AC_SX679_.jpg",
    sale_price: "59.99",
    suggested_price: "79.99",
    description: "Smart Fitness Tracker",
    ar_title: "جهاز تتبع اللياقة البدنية الذكي",
    category_ar_title: "أجهزة ذكية",
    store_image: AlibabaLogo,
  },
  {
    id: 41,
    main_image: "https://m.media-amazon.com/images/I/71QMRSHkgvL._AC_SL1500_.jpg",
    sale_price: "25.00",
    suggested_price: "30.00",
    description:
      "مصباح مكتب LED 2 في 1 قابل للتعديل وقابل للدوران 120 درجة للعناية بالعين للتعتيم مزدوج الرأس للمهندس المعماري للمنزل والمكتب ومنضدة العمل والقراءة متعدد الألوان",
    ar_title: "مصباح مكتب LED 2 في 1",
    category_ar_title: "إضاءة",
    store_image: AlibabaLogo,
  },
  {
    id: 42,
    main_image: "https://m.media-amazon.com/images/I/61EYc8FpAKL._AC_SL1200_.jpg",
    sale_price: "99.00",
    suggested_price: "120.00",
    description: "Portable Blender for Smoothies",
    ar_title: "خلاط محمول للعصائر",
    category_ar_title: "مطبخ",
    store_image: AlibabaLogo,
  },
  {
    id: 43,
    main_image: "https://s.alicdn.com/@sc04/kf/Hc49589b743da4411ba1ec2f167e28954K.jpg_720x720q50.jpg",
    sale_price: "13.99",
    suggested_price: "18.99",
    description: "Foldable Yoga Mat",
    ar_title: "حصيرة يوغا قابلة للطي",
    category_ar_title: "رياضة",
    store_image: AlibabaLogo,
  },
  {
    id: 44,
    main_image: "https://i.ebayimg.com/images/g/MdkAAOSwciFm3D8y/s-l1600.webp",
    sale_price: "50.00",
    suggested_price: "70.00",
    description: "Electric Wine Opener",
    ar_title: "فتاحة نبيذ كهربائية",
    category_ar_title: "مطبخ",
    store_image: AlibabaLogo,
  },
  {
    id: 45,
    main_image: "https://m.media-amazon.com/images/I/61DgvzuO+WL._AC_SL1500_.jpg",
    sale_price: "40.00",
    suggested_price: "60.00",
    description: "Cordless Handheld Vacuum Cleaner",
    ar_title: "مكنسة كهربائية محمولة بدون سلك",
    category_ar_title: "أجهزة كهربائية",
    store_image: AlibabaLogo,
  },
  {
    id: 46,
    main_image: "https://m.media-amazon.com/images/I/61jcwb8OY1L._AC_SL1100_.jpg",
    sale_price: "18.99",
    suggested_price: "28.99",
    description: "Portable Speaker with RGB Lights",
    ar_title: "مكبر صوت محمول مع أضواء RGB",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 47,
    main_image: "https://m.media-amazon.com/images/I/61ZxeC3UekL._AC_SL1500_.jpg",
    sale_price: "22.99",
    suggested_price: "30.00",
    description: "Electric Kettle with Auto Shut Off",
    ar_title: "غلاية كهربائية مع إيقاف التشغيل التلقائي",
    category_ar_title: "مطبخ",
    store_image: AlibabaLogo,
  },
  {
    id: 48,
    main_image: "https://m.media-amazon.com/images/I/51rcofvSTuL._AC_SL1000_.jpg",
    sale_price: "10.00",
    suggested_price: "15.00",
    description: "Bluetooth Car Kit",
    ar_title: "طقم بلوتوث للسيارة",
    category_ar_title: "إلكترونيات",
    store_image: AlibabaLogo,
  },
  {
    id: 49,
    main_image: "https://m.media-amazon.com/images/I/516maLua1IL._AC_SX679_.jpg",
    sale_price: "24.99",
    suggested_price: "35.99",
    description: "Backpack with USB Charging Port",
    ar_title: "حقيبة ظهر مع منفذ شحن USB",
    category_ar_title: "أزياء",
    store_image: AlibabaLogo,
  },
  {
    id: 50,
    main_image: "https://m.media-amazon.com/images/I/71TUVu-sYZL._AC_SL1500_.jpg",
    sale_price: "16.99",
    suggested_price: "22.99",
    description: "Portable Air Cooler",
    ar_title: "مبرد هواء محمول",
    category_ar_title: "أجهزة كهربائية",
    store_image: AlibabaLogo,
  },
];
