import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { fadeIn } from '../../Variants';
import Pusher from 'pusher-js';
import { BASE_URL } from '../config';
import axios from 'axios';
import { toast } from 'react-toastify';


const Notifications = () => {
    const [isModelOpen, setIsModelOpen] = useState(false)
    const [notifications, setNotifications] = useState([]);
    const [notificationHeader, setNotificationHeader] = useState("")
    const [notificationBody, setNotificationBody] = useState("")
    const [groups, setGroups] = useState([])
    const [selectedGroup, setSelectedGroup] = useState()
    const [newNotificationsCount, setNewNotificationsCount] = useState(0);


    useEffect(() => {
        const token = localStorage.getItem("token");

        const pusher = new Pusher("782989169b1064e404db", {
            cluster: 'eu',
            authEndpoint: '/broadcasting/auth',
            auth: {
                headers: { Authorization: `Bearer ${JSON.parse(token)}` },
            },
        });

        const channel = pusher.subscribe('notifications');
        // Bind to an event
        channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (data) => {
            console.log(data);

            // Increment the new notifications count
            setNewNotificationsCount(prevCount => prevCount + 1);
            alert('Received my-event with message: ' + data.message);
        });
        // Clean up the Pusher instance when the component unmounts
        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, [])


    useEffect(() => {
        try {
            const token = localStorage.getItem("token");
            const response = axios.get(`${BASE_URL}/groups`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`,
                },
            }
            )
                .then((res) => {
                    setGroups(res.data);

                })

        } catch (error) {
            console.log(error);
        }
    }, [])

    const handleSelect = (e) => {
        const currentCategory = groups.map((el) => {
            if (Number(e.target.value) === el.id) setSelectedGroup(el);
        });
    };

    const handleSendNotification = () => {
        const token = localStorage.getItem("token");
        const data = {
            "lang": "ar",
            "group_ids": [selectedGroup.id],
            "title": notificationHeader,
            "message": notificationBody
        }
        console.log(data);
        try {
            const response = axios.post(`${BASE_URL}/send-notification-pusher`, data, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`,
                },
            })
                .then((res) => {
                    console.log(res.data.message);
                    toast.success(res.data.message)
                })
        } catch (error) {
            console.log(error);
        }

        setIsModelOpen(false)
    }
    return (
        <div>
            <div className='flex items-center justify-between my-8'>
                <h2 className='text-2xl font-bold'>
                    الإشعارات
                </h2>
                <div>
                    <button onClick={() => setIsModelOpen(true)} className='bg-menu-super_admin text-white py-2 px-4 rounded-md'>إرسال إشعار جديد</button>
                </div>
            </div>
            <div className="bg-white py-4 px-2 rounded-2xl">
                <div className="overflow-x-auto">
                    <table className="w-full min-w-[600px] mb-5 text-xs lg:text-sm overflow-hidden">
                        <thead className="border-b text-start">
                            <tr>
                                <th className="py-2">
                                    <input type="checkbox" />
                                </th>
                                <th className="p-2 text-start">الإشعار</th>
                                <th className="p-2 text-start">المجموعة</th>
                                <th className="p-2 text-start">التاريخ</th>
                                <th className="p-2 text-start">الوقت</th>
                                <th className="p-2 text-start">الإجراء</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notifications.map((notification, index) => (
                                <th key={index}>{notification.message}</th>
                            ))}
                        </tbody>

                    </table>
                </div>
            </div>


            {/** Model Send Notification */}
            {isModelOpen &&
                <div
                    className='fixed bg-black/50 inset-0 flex justify-center items-center w-screen h-screen '>
                    <motion.div
                        variants={fadeIn("up", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.7 }}
                        className='bg-white p-4 rounded-lg w-4/5 lg:w-1/3'>
                        <h3 className='font-semibold text-lg mb-4'>إرسال إشعار
                        </h3>
                        <form className='flex flex-col'>
                            <div className="flex flex-col">
                                <label>العنوان  :</label>
                                <input
                                    type="text"
                                    onChange={(e) => { setNotificationHeader(e.target.value) }}
                                    className="w-full p-2 border rounded mb-4"
                                    required
                                />
                            </div>
                            <div>
                                <label>المحتوى : </label>
                                <textarea
                                    onChange={(e) => { setNotificationBody(e.target.value) }}
                                    className="w-full p-2 border rounded mb-4"
                                    required
                                />
                            </div>

                            <div className='flex flex-col mb-4'>
                                <label className='text-sm mb-2'>المجموعة</label>
                                <select
                                    onChange={handleSelect}
                                    className='border border-gray-300 rounded-md py-2 px-4'>
                                    <option value="1">إختر مجموعة</option>
                                    {groups.map((group) => {
                                        return <option value={group.id}>{group.name}</option>

                                    })}
                                    {/* <option value="option2">Option 2</option> */}
                                </select>
                            </div>

                            <div className='flex justify-end mt-8'>
                                <button onClick={() => setIsModelOpen(false)} type="button" className="px-4 py-2 bg-menu-super_admin/30 hover:bg-menu-super_admin/25 rounded-md me-2">إلغاء </button>
                                <button onClick={handleSendNotification} className="px-4 py-2 bg-menu-super_admin hover:bg-menu-default text-white rounded-md ">إرسال</button>
                            </div>
                        </form>
                    </motion.div>
                </div>}

        </div>
    )
}

export default Notifications