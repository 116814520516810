// ErrorPayment.jsx
import { Link } from 'react-router-dom';

const ErrorPayment = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-red-50">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md text-center">
        <h1 className="text-3xl font-bold text-red-600">فشل الدفع</h1>
        <p className="mt-4 text-gray-600">
        لسوء الحظ، لم يتم معالجة الدفع الخاص بك. يرجى المحاولة مرة أخرى.
        </p>
        <div className="mt-6">
          <Link
            to="/dashboard/subscription-plan" 
            className="bg-menu-default text-white py-2 px-4 rounded hover:bg-red-700 transition"
          >
            جرب مرة أخرى
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPayment;
