// SuccessPayment.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const SuccessPayment = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-green-50">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md text-center">
        <h1 className="text-3xl font-bold text-green-600">Payment Successful!</h1>
        <p className="mt-4 text-gray-600">
          Thank you for your payment. Your transaction has been processed successfully.
        </p>
        <div className="mt-6">
          <Link 
            to="/dashboard" 
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-500/80 transition"
          >
            Go to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuccessPayment;
