import React from "react";
import DotVetrical from "/public/Icons/dots-vertical.svg";
import Mail from "/public/Icons/mail-1.svg";
import Link from "/public/Icons/link.svg";
import Mouse from "/public/Icons/mouse.svg";
import Users from "/public/Icons/users.svg";
import Alert from "/public/Icons/AlertReportProblem.svg";
import Ban from "/public/Icons/ban.svg";

const Array = [
  {
    icon: <img src={Mail} />,
    name: "رسائل البريد الإلكتروني ",
    num: "0",
    num2: "0.0%",
    BgColor: "bg-green-100",
  },
  {
    icon: <img src={Link} />,
    name: "زيارات الموقع",
    num: "0",
    num2: "0.0%",
    BgColor: "bg-blue-100",
  },
  {
    icon: <img src={Mouse} />,
    name: "نقرات الموقع",
    num: "0",
    num2: "0.0%",
    BgColor: "bg-red-100",
  },
  {
    icon: <img src={Users} />,
    name: "اشتراكات",
    num: "0",
    num2: "0.0%",
    BgColor: "bg-purple-100",
  },
  {
    icon: <img src={Alert} />,
    name: "الشكاوي",
    num: "0",
    num2: "0.0%",
    BgColor: "bg-gray-200",
  },
  {
    icon: <img src={Ban} />,
    name: " الغاء الاشتراك",
    num: "0",
    num2: "0.0%",
    BgColor: "bg-red-100",
  },
];

const MarketingCampaigns = () => {
  return (
    <div className=" col-span-6 lg:col-span-2 w-full px-3 ">
      <div className="bg-white rounded-xl p-6 ">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-gray-700 text-lg font-bold">
            حملات التسويق الشهرية
          </h2>
          <img src={DotVetrical} alt="" />
        </div>
        <p className="text-gray-400 text-sm mb-4">0  زائر</p>

        <div className="space-y-4 grid grid-cols-1">
          {Array.map((item) => (
            <div
              key={item.name}
              className="grid grid-cols-6 gap-2 items-center justify-items-start"
            >
              <div className={`${item.BgColor} p-2 rounded-md ml-3 `}>
                {item.icon}
              </div>
              <span className="text-sm col-span-3">{item.name}</span>
              <span className="text-sm">{item.num}</span>
              <span className="text-green-500 text-sm">{item.num2}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MarketingCampaigns;
