/* eslint-disable react/prop-types */
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "/public/Avatar.png";
import { logout } from "../redux/reducer/authSlice";
import {
  getDashboardArrayFreePackage,
  getDashboardArraySeller,
  getDashboardArraySupplier,
  getSuperAdminArray,
} from "../navbarContent";
import { useEffect, useState } from "react";
import axios from "axios";
const MobileMenu = ({ open, handleClick, onAction }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const freePackageMenu = getDashboardArrayFreePackage(t);
  const sellerMenu = getDashboardArraySeller(t);
  const supplierMenu = getDashboardArraySupplier(t);
  const superAdminMenu = getSuperAdminArray(t);
  const [permissions, setPermissions] = useState([]);
  const menuItems = getSuperAdminArray(t, permissions);
  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("paymentVisited");
    setTimeout(() => {
      navigate("/login");
    }, 0);
  };
  useEffect(() => {
    if (user?.user?.user_type === "super_admin") {
      const token = localStorage.getItem("token");
      axios
        .get(
          `https://backend-sbyp.sbyp-sa.com/api/roles/permissions?role_name=${user?.user?.roles[0]?.name}`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            setPermissions(response.data.data.map((item) => item.name));
          }
        })
        .catch((error) => {
          console.error("Error fetching permissions:", error);
        });
    }
  }, [user?.user?.user_type, user?.user?.roles]);
  return (
    <AnimatePresence mode="wait">
      {open ? (
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ duration: 0.3 }}
          className="absolute top-20 left-0 w-full  z-20 flex justify-start"
        >
          <div className={`text-xl font-semibold bg-menu-${user?.user?.user_type} text-white py-6  rounded-md w-full`}>
            <ul className="flex flex-col justify-center  items-center gap-4 cursor-pointer ">
              {user?.user.user_type === "super_admin" ? (
                <>
                  {menuItems.map((item, index) => (
                    <button
                      key={index}
                      className="border-b border-white/20 w-full pb-4"
                      onClick={() => {
                        handleClick(item.path);
                      }}
                    >
                      {t(item.text)}
                    </button>
                  ))}
                </>
              ) : user?.user.user_type === "supplier" &&
                user.user.end_date !== null ? (
                <>
                  {supplierMenu.map((item, index) => (
                    <button
                      key={index}
                      className="border-b border-white/20 w-full pb-4"
                      onClick={() => {
                        handleClick(item.path);
                      }}
                    >
                      {t(item.text)}
                    </button>
                  ))}
                </>
              ) : user?.user.user_type === "seller" &&
                user?.user.package_price == 0.0 ? (
                <>
                  {freePackageMenu.map((item, index) => (
                    <button
                      key={index}
                      className="border-b border-white/20 w-full pb-4"
                      onClick={() => {
                        handleClick(item.path);
                      }}
                    >
                      {t(item.text)}
                    </button>
                  ))}
                </>
              ) : (
                <>
                  {sellerMenu.map((item, index) => (
                    <button
                      key={index}
                      className="border-b border-white/20 w-full pb-4"
                      onClick={() => {
                        handleClick(item.path);
                      }}
                    >
                      {t(item.text)}
                    </button>
                  ))}
                </>
              )}

              <Link
                to="/dashboard/profile"
                onClick={() => {
                  handleClick();
                  onAction();
                }}
                className={`border-b border-white/20 pb-4 flex  w-full text-white bg-menu-${user?.user?.user_type} justify-center items-center `}
              >
                <img className="me-2" src={Avatar} alt="avatar" />
                <span>{user?.user.user_name}</span>
              </Link>

              <button onClick={handleLogout} className="">
                تسجيل الخروج
              </button>
            </ul>
          </div>
        </motion.div>
      ) : (
        ""
      )}
    </AnimatePresence>
  );
};

export default MobileMenu;
